import React from 'react';
import { useTranslation } from 'react-i18next';
import '../App.css';
import medicationImg from '../assets/image-s2.png'; 
import deliveryImg from '../assets/image-s4.png';
import consultationImg from '../assets/image-s3.png'; 
import financialImg from '../assets/image-s1.png'; 

const services = [
  {
    key: "insurance_financial_assistance",
    image: financialImg,
  },
  {
    key: "personalized_medication_management",
    image: medicationImg,
  },
  {
    key: "consultations_with_pharmacists",
    image: consultationImg,
  },
  {
    key: "home_delivery_shipping",
    image: deliveryImg,
  },
];

const Services = () => {
  const { t } = useTranslation();

  return (
    <div className="services" id="services">
      <p className='tit'>{t('our_services')}</p>
      <div className="services-container">
        {services.map((service, index) => (
          <div key={index} className="service">
            <div className="service-text">
              <h3>{t(`services.${service.key}.title`)}</h3>
              <p>{t(`services.${service.key}.description`)}</p>
            </div>
            <img src={service.image} alt={t(`services.${service.key}.title`)} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
