// PrivacyPolicy.js
import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <div className="hero-section2">
        {/* <img src={image} alt="" className="main-image" /> */}
        <div className="main-content">
          <div className="overlay">
            <p className="text-logo">Privacy Policy </p>
          </div>
        </div>{" "}
      </div>
      <div className="terms">
        <section>
          <h2>In General</h2>
          <p>
            In this document we describe how we collect, use and handle your
            information when you use our websites and services ("Services").
            <p />
            <p>
              At Physician Management Corp. of America, including its
              subsidiaries or related/affiliated entities (collectively the
              "Company", “we”, "us", or "our"), we value your trust and respect
              your privacy.
            </p>
            <p>
              The Company strives to support users like you by offering our
              Services, while respecting your privacy expectations and
              protecting your Personal Information.
            </p>
            <p>
              The Company is required by the privacy regulations issued under
              the Health Insurance Portability and Accountability Act of 1996
              (HIPAA) to maintain the privacy of our patient’s medical
              information and to provide patients with notice of our legal
              duties and privacy practices with respect to their medical
              information. Protecting the confidentiality of your personal
              information has been, and always will be, a top priority for the
              Company. We want you to understand the measures we take to protect
              your health information and the situations where we might share it
              in the course of providing you with your medical care.
            </p>
            <p>
              Under HIPAA, protected health information (“PHI”) is considered to
              be individually identifiable information relating to the past,
              present, or future health status of an individual that is created,
              collected, or transmitted, or maintained by a HIPAA-covered entity
              in relation to the provision of healthcare, payment for healthcare
              services, or use in healthcare operations (PHI healthcare business
              uses).
            </p>
            <p>
              Health information such as diagnoses, treatment information,
              medical test results, and prescription information are considered
              protected health information under HIPAA, as are national
              identification numbers and demographic information such as birth
              dates, gender, ethnicity, and contact and emergency contact
              information. PHI relates to physical records, while ePHI is any
              PHI that is created, stored, transmitted, or received
              electronically.
            </p>
            <p>
              PHI only relates to information on patients or health plan
              members. It does not include information contained in educational
              and employment records, that includes health information
              maintained by a HIPAA covered entity in its capacity as an
              employer.
            </p>
            <p>
              PHI is only considered PHI when an individual could be identified
              from the information. If all identifiers are stripped from health
              data, it ceases to be protected health information and the HIPAA
              Privacy Rule’s restrictions on uses and disclosures no longer
              apply.
            </p>
            <p>
              By "Personal Information" or “Business Information” (collectively
              the “Personal Information”), we mean information about an
              identifiable individual or business such as a person's name, email
              address, residential address, telephone number, and in some cases,
              more sensitive information such as but not limited to gender,
              demographic, among other. In some instances, we may receive
              information about you from other sources, or third parties from
              whom we obtain data, and combine this data with information we
              already have about you or your business. This helps us to update,
              expand, analyze our records, among other.
            </p>
            <p>
              To demonstrate our commitment to protecting your privacy, we have
              developed this Privacy Policy, which describes how we will
              collect, use, disclose, retain and protect Personal Information in
              order to provide you with our Services offered and maintained by
              us and our affiliates from time to time. This Privacy Policy
              applies to all domains of which are owned and operated by the
              Company.
            </p>
            <p>
              WHEN REGISTERING FOR USER ACCOUNT OR OTHERWISE USING THE SITE, YOU
              CONFIRM THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO COMPLY WITH
              THIS POLICY. PLEASE READ THIS POLICY CAREFULLY AS IT DESCRIBES OUR
              PRIVACY POLICIES AND PRACTICES. IF YOU DO NOT AGREE WITH OUR
              PRIVACY POLICIES AND PRACTICES, DO NOT USE THE SITE.
            </p>
            <p>THIS POLICY MAY CHANGE FROM TIME TO TIME WITHOUT NOTICE.</p>
          </p>
        </section>

        <section>
          <h2>Scope</h2>
          <p>
            For the purposes of this Privacy Policy, the term "Site" means this
            website, and all Apps, software, related webpages, social media, and
            related websites operated by affiliates or divisions of the Company,
            but does not include any third-party websites which are linked to or
            may link from this website whether or not such third-party websites
            are used in connection with the Services. "Company Apps" means the
            Site and other applications which provide access to the Services
            offered by the Company and its affiliates from time to time. When
            this Policy does not apply, you should read the applicable privacy
            policy and related terms posted on the website, application, or
            other service you are using before providing them with your
            information.
          </p>
        </section>
        <section>
          <h2>Accountability</h2>
          <p>
            The Company has designated a Designated Agent who is responsible,
            for receiving your privacy-related questions, and for providing you
            with information about our privacy practices.
          </p>
        </section>
        <section>
          <h2>Company Designated Agent:</h2>
          <p>Email: complianceUltimedPharmacy.com</p>
        </section>
        <section>
          <h2>Identifying Purposes</h2>
          <p>
            The Company collects, uses, and discloses Personal Information only
            for the purposes of providing you with our Services and for such
            other ancillary purposes consented to by you as a user.
          </p>
          <p>
            We process Personal Information to operate, improve, understand and
            personalize our Services. More specifically, the Company collects,
            uses and discloses information (which may include Personal
            Information) for the following purposes:
          </p>
          <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
            <li>To provide you with our Services.</li>
            <li>To create and manage user profiles.</li>
            <li>
              To promote or offer you products, services and offers (including
              new and other Services which are offered by us, our sponsors and
              partners) that may be relevant to you.
            </li>
            <li>
              To contact you for the purposes of product information, service
              updates, notifications relating to the Services, newsletters and
              tailored savings opportunity messages.
            </li>
            <li>
              To contact you for the purposes of product information, service
              updates, notifications relating to the Services, newsletters and
              tailored savings opportunity messages.
            </li>
            <li>
              To monitor system usage, server and software performance, to
              improve system design, to create benchmarks and to conduct
              trending analyses.
            </li>
            <li>
              To provide support and assistance for the Services. To assist you
              with technical support issues. It is important to remember that
              most technical issues can be resolved without a Company customer
              service representative viewing your Personal Information.
            </li>
            <li>To process orders.</li>
            <li>
              To personalize website content and communications based on your
              preferences.
            </li>
            <li>To respond to user inquiries.</li>
            <li>To fulfill user requests.</li>
            <li>To meet contract or legal obligations.</li>
            <li>
              To comply with any laws, regulation, court orders, subpoenas or
              other legal process or investigation and to protect ourselves and
              other individuals from harm.
            </li>
            <li>
              To assist in due diligence relating to any corporate action such
              as a financing, merger, amalgamation, sale or divestiture,
              provided that reasonable safeguards are taken to protect the
              confidentiality of Personal Information in our possession.
            </li>
            <li>To resolve disputes.</li>
            <li>
              To protect against or deter fraudulent, illegal or harmful
              actions. . To enforce our “Legal Documents”: Terms of Use, Privacy
              Policy, and the Subscription Agreement, among other, for the Site.
              We will only process your Personal Information if we have a lawful
              basis for doing so. Lawful bases for processing include consent,
              contractual necessity and our "legitimate interests" or the
              legitimate interest of others, as further described below.{" "}
            </li>
          </ul>
          <p>
            Contractual Necessity: We process your first and last name, email
            address, location and credit/debit card information as a matter of
            "contractual necessity", meaning that we need to process the data to
            perform under our “Legal Documents” with you, which enables us to
            provide you with the Services. When we process data due to
            contractual necessity, failure to provide such Personal Information
            will result in your inability to use some or all portions of the
            Services that require such data.
          </p>
          <p>
            Legitimate Interest: We also process the Personal Information above
            when we believe it furthers our legitimate interests, examples of
            which include:
          </p>
          <ul>
            <li>
              Operation and improvement of our business, products and services
            </li>
            <li>Marketing of our products and services</li>
            <li>Provision of customer support</li>
            <li>Protection from fraud or security threats</li>
            <li>Compliance with legal obligations</li>
            <li>Completion of corporate transactions</li>
          </ul>
          <p>
            Consent: In some cases, we process Personal Information based on the
            consent you expressly grant to us at the time we collect such data.
            When we process Personal Information based on your consent, it will
            be expressly indicated to you at the point and time of collection.
          </p>
          <p>
            Other Processing Grounds: From time to time we may also need to
            process Personal Information to comply with a legal obligation, if
            it is necessary to protect the vital interests of you or other data
            subjects, or if it is necessary for a task carried out in the public
            interest.
          </p>
        </section>
        <section>
          <h2>Consent</h2>
          <p>
            We collect Personal Information directly from you or other third
            parties. We use and disclose the Personal Information only as
            described in this policy and our “Legal Documents”.
          </p>
          <p>
            Withdrawing your consent will not apply to actions the Company has
            already taken based on your prior consent.
          </p>
          <p>
            In order to use certain Services, you may be required to provide
            personal information relating to yourself and your employees,
            agents, contractors and representatives from time to time. You
            acknowledge, and represent to us that you only collect, use and
            disclose personal information in compliance with applicable privacy
            laws. You further represent and warrant to us that you have obtained
            all required consents from your employees, agents, contractors and
            representatives whose personal information will be disclosed to us
            or to our third-party service providers in connection with the
            Services. You further agree to indemnify and hold the Company, its
            affiliates, subsidiaries, partners, service providers, suppliers and
            contractors and each of their respective officers, directors,
            agents, and employees (collectively, the "Parties"), harmless for
            any loss, cost, complaint, damage, claim or liability whatsoever
            arising from your collection, use and disclosure of personal
            information relating to your employees, agents, contractors and
            representatives.
          </p>
        </section>
        <section>
          <h2>Collection of personally-identifiable information</h2>
          <p>
            The Company must collect some of your personally-identifiable
            information to provide, support, improve, and market the Site and
            the Company’s Content to you. Some of the ways that the Company may
            collect such information include information that you provide to the
            Company directly, information about you that the Company
            automatically collects from log data, IP addresses, and aggregated
            user data, cookies and similar technologies, and information that
            you give the Company about your contacts, and your chat histories,
            if applicable. The Company will only use your
            personally-identifiable information when the law allows the Company
            to do so and in the ways described in this Privacy Policy.
          </p>

          <p>
            The Company collects, processes, stores, and transfers
            personally-identifiable information that you submit through the Site
            and its other services and Content, including:
          </p>

          <p>
            <strong>Information You Provide to Us.</strong> We receive and store
            any information you knowingly provide to us. For example, we collect
            Personal Information such as your name, email address, username,
            insurance information, social security number, and health history.
            We may de-identify your Personal Information so that you cannot be
            individually identified and provide that information to our
            partners.
          </p>

          <p>
            If you have provided us with a means of contacting you, we may use
            such means to communicate with you. For example, we may send you
            promotional offers or communicate with you about your use of the
            Services. Also, we may receive a confirmation when you open a
            message from us. This confirmation helps us make our communications
            with you more interesting and improve our services. If you do not
            want to receive communications from us, please indicate your
            preference by following the unsubscribe link provided in each
            e-mail. Please note that if you do not want to receive legal notices
            from us, those legal notices will still govern your use of the
            Services, and you are responsible for reviewing such legal notices
            for changes.
          </p>

          <p>
            <strong>Business Information.</strong> This includes information
            provided in the course of the contractual or client relationship
            between you or your organization and us, or otherwise voluntarily
            provided by you or your organization.
          </p>

          <p>
            <strong>Marketing and Communications Preferences.</strong> These
            include your preferences in receiving marketing from us and third
            parties and your communication preferences. We offer you control
            over the various types of optional communications made available by
            us to you, including opt-in and opt-out capabilities. At times, you
            also will receive mandatory communications from our Site when we
            deliver the services and Content that you request or need to advise
            you of important notices about the Site. In such cases, your only
            option for opting out of mandatory communications is to cancel your
            user account with us.
          </p>

          <p>
            <strong>Contact Information, Feedback, and Inquiries.</strong> When
            you submit feedback to us, contact us for support, or ask us
            questions, we may collect your first and last name, email address,
            telephone number, and/or other contact information in order to
            respond to your feedback, provide support, or answer your questions.
            This includes records and copies of your correspondence (including
            email addresses and phone numbers) with us, if any.
          </p>

          <p>
            <strong>Information Collected Automatically.</strong> Whenever you
            interact with our Services, we automatically receive and record
            information on our server logs from your browser including your IP
            address, "cookie" information, and the page you requested. "Cookies"
            are identifiers we transfer to your computer or mobile device that
            allow us to recognize your browser or mobile device and tell us how
            and when pages and features in our Services are visited and by how
            many people. You may be able to change the preferences on your
            browser or mobile device to prevent or limit your computer or
            device’s acceptance of cookies, but this may prevent you from taking
            advantage of some of our features. Our advertising partners may also
            transmit cookies to your computer or device, when you click on ads
            that appear on the Services. Also, if you click on a link to a
            third-party website, such third party may also transmit cookies to
            you. This Privacy Policy does not cover the use of cookies by any
            third parties.
          </p>

          <p>
            <strong>
              Special Categories of Information and Sensitive Data.
            </strong>{" "}
            We may require that you provide any special categories of personal
            data and information in order to access and use our Site. Sensitive
            Personal Data and Information is generally defined by law to include
            details about your race, ethnicity, religious or philosophical
            beliefs, sex life, sexual orientation, political opinions, trade
            union membership, health, and/or genetic and biometric data and the
            collection of such data and information subject to certain
            protections. We also do not intentionally collect or require any
            information from you about criminal convictions and offences and
            request that you do not provide it to us. You should only share
            Sensitive Personal Data and Information with trusted persons. We are
            not liable for any harm or loss whatsoever occurring as a result of
            your decision to share such data with us or third parties via the
            Site.
          </p>

          <p>
            Notwithstanding the foregoing, we may use Sensitive Personal Data
            and Information we receive to comply with lawful user requests and
            to enforce our “Legal Documents”, which may include deleting any
            Sensitive Personal Data and Information you submit that is deemed
            unlawful or unnecessary for your access and use of the Site.
          </p>

          <p>
            We reserve the right to remove, at our sole discretion, any
            information (such as usernames, profile pictures, names, etc.) that
            you provide when you register for or use the Site or any services
            therein, if we believe it is inappropriate or violates our “Legal
            Documents”. We have feedback functionality on the Site that allows
            you to submit comments or suggestions. We may collect your name or
            username and email address in order to follow up with you or thank
            you for a suggestion.
          </p>
        </section>
        <section>
          <h2>How and With Whom Do We Share Your Data?</h2>
          <p>
            <strong>Personal Health Information</strong>
          </p>

          <p>
            Under the HIPAA Privacy Rule, we are permitted, but not required, to
            use and disclose PHI, without an individual’s authorization, for the
            following purposes or situations:
          </p>

          <ul>
            <li>
              We are permitted to disclose PHI to the individual who is the
              subject of the information.
            </li>
            <li>
              <strong>Treatment, Payment, and Healthcare Operations</strong>
            </li>
            <ul>
              <li>
                <strong>Treatment</strong> is the provision, coordination, or
                management of healthcare and related services for an individual
                by a health care provider.
              </li>
              <li>
                <strong>Payment</strong> encompasses activities of a health plan
                to obtain premiums, determine or fulfill responsibilities for
                coverage and provision of benefits, and furnish or obtain
                reimbursement for health care delivered to an individual.
                Payment also includes activities of a health care provider to
                obtain payment or be reimbursed for the provision of health care
                to an individual.
              </li>
              <li>
                <strong>Health care operations</strong> are any of the following
                activities:
              </li>
              <ul>
                <li>
                  Quality assessment and improvement activities, including case
                  management and care coordination;
                </li>
                <li>
                  Competency assurance activities, including provider or health
                  plan performance evaluation, credentialing, and accreditation;
                </li>
                <li>
                  Conducting or arranging for medical reviews, audits, or legal
                  services, including fraud and abuse detection and compliance
                  programs;
                </li>
                <li>
                  Specified insurance functions, such as underwriting, risk
                  rating, and reinsuring risk;
                </li>
                <li>
                  Business planning, development, management, and
                  administration; and
                </li>
                <li>
                  Business management and general administrative activities of
                  the entity, including but not limited to:
                </li>
                <ul>
                  <li>
                    De-identifying protected health information (removing
                    specific information about a patient that can be used alone
                    or in combination with other information to identify that
                    patient);
                  </li>
                  <li>
                    Creating a limited data set (identifiable healthcare
                    information that covered entities are permitted to share
                    with certain entities for research purposes, public health
                    activities, and healthcare operations, without obtaining
                    prior patient written authorization); and
                  </li>
                  <li>
                    Certain fundraising for the benefit of the covered entity.
                  </li>
                </ul>
              </ul>
            </ul>
          </ul>

          <p>
            <strong>
              Use and Disclosure of PHI to which an Individual Has an
              Opportunity to Agree or Object:
            </strong>
          </p>
          <p>
            Informal permission may be obtained by asking the individual
            outright, or by circumstances that clearly give the individual the
            opportunity to agree, acquiesce, or object. Where the individual is
            incapacitated, in an emergency situation, or not available, covered
            entities generally may use and disclose PHI, if in the exercise of
            their professional judgment, the use or disclosure is determined to
            be in the best interests of the individual. Uses or disclosures to
            which an individual has an opportunity to agree or object include:
          </p>

          <ul>
            <li>
              <strong>Facility Directories.</strong> Many health care
              facilities, such as hospitals, maintain a directory of patient
              contact information. A covered health care provider may rely on an
              individual’s informal permission to list in its facility directory
              the individual’s name, general condition, religious affiliation,
              and location in the provider’s facility. The provider may then
              disclose the individual’s condition and location in the facility
              to anyone asking for the individual by name, and also may disclose
              religious affiliation to clergy. Members of the clergy are not
              required to ask for the individual by name when inquiring about
              patient religious affiliation.
            </li>
            <li>
              <strong>Notification and Other Purposes.</strong> A covered entity
              also may rely on an individual’s informal permission to disclose
              to the individual’s family, relatives, or friends, or to other
              persons whom the individual identifies, PHI directly relevant to
              that person’s involvement in the individual’s care or payment for
              care. A covered entity may rely on an individual’s informal
              permission to use or disclose PHI for the purpose of notifying
              (including identifying or locating) family members, personal
              representatives, or others responsible for the individual’s care
              of the individual’s location, general condition, or death.
            </li>
          </ul>
        </section>
        <section>
          <h2>Personal Information</h2>
          <p>
            We share Personal Information with vendors, third party service
            providers and agents who work on our behalf and provide us with
            services related to the purposes described in the “Legal Documents”.
            These parties include:
          </p>

          <ul>
            <li>Payment processors;</li>
            <li>Fraud prevention service providers;</li>
            <li>Ad networks and partners;</li>
            <li>Analytics service providers;</li>
            <li>Hosting service providers.</li>
          </ul>

          <p>
            We also share Personal Information when necessary to complete a
            transaction initiated or authorized by you or provide you with a
            product or service you have requested, including to other users
            (where you post information publicly or as otherwise necessary to
            process a transaction initiated or authorized by you through the
            Services).
          </p>

          <p>
            We also share Personal Information when we believe it is necessary
            to:
          </p>

          <ul>
            <li>
              Comply with applicable law or respond to valid legal process,
              including from law enforcement or other government agencies;
            </li>
            <li>
              Protect us, our business or our users, for example to enforce our
              “Legal Documents”, prevent spam or other unwanted communications
              and investigate or protect against fraud; and
            </li>
            <li>Maintain the security of our products and services.</li>
          </ul>

          <p>
            We also may share Personal Information with third parties without
            your prior consent.
          </p>

          <p>
            Furthermore, if we choose to buy or sell assets, user information is
            typically one of the transferred business assets. Moreover, if we,
            or substantially all of our assets, were acquired, or if we go out
            of business or enter bankruptcy, user information would be one of
            the assets that is transferred or acquired by a third party, and we
            would share Personal Information with the party that is acquiring
            our assets. You acknowledge that such transfers may occur, and that
            any acquirer of us or our assets may continue to use your Personal
            Information as set forth in this policy.
          </p>
        </section>
        <section>
          <h2>How Long Do We Retain Your Personal Information?</h2>
          <p>
            We retain Personal Information about you for as long as you have an
            open account with us or as otherwise necessary to provide you
            Services. In some cases, we retain Personal Information for longer,
            if doing so is necessary to comply with our legal obligations,
            resolve disputes or collect fees owed, or is otherwise permitted or
            required by applicable law, rule or regulation, such as marketing
            purposes.
          </p>
        </section>
        <section>
          <h2>What Security Measures Do We Use?</h2>
          <p>
            The HIPAA Security Rule requires covered entities to protect against
            reasonably anticipated threats to the security of PHI. Covered
            entities must implement safeguards to ensure the confidentiality,
            integrity, and availability of PHI, although HIPAA is not technology
            specific and the exact safeguards that should be implemented are
            left to the discretion of the covered entity.
          </p>

          <p>
            We seek to protect PHI and Personal Information by implementing
            industry best practices appropriate to the sensitivity of your
            Personal Information. We use administrative, technical, and physical
            safeguards to protect your Personal Information against loss, theft,
            and unauthorized access, use, disclosure, copying, modification,
            disposal, or destruction in accordance with applicable legal
            requirements and industry best practices.
          </p>

          <p>
            We ensure that any third party acting on our behalf in respect of
            your Personal Information maintains reasonable and appropriate
            safeguards. For example, these third parties classify customer
            account data as "super sensitive". This means that access to such
            data is strictly limited based on business need and this data cannot
            be transmitted without the use of approved encryption methods.
          </p>

          <h2>Accessing, Updating, and Deleting Your Personal Information</h2>
          <p>
            Absent exceptional circumstances, we offer you reasonable access to
            and control over your Personal Information at any time, at no
            charge. Please inform yourself of your rights by reviewing the list
            below. You may use the “Contact Us” details at the end of this
            Policy to exercise your rights and choices.
          </p>

          <p>
            We try to respond to all legitimate requests regarding your personal
            information within one month. Occasionally, it could take us longer
            than a month if your request is particularly complex or you have
            made a number of requests. In such cases, we will notify you and
            keep you updated. The timeframes described here do not apply to our
            response times for addressing ordinary customer service and support
            requests unrelated to the exercise of Personal Information access
            rights.
          </p>

          <p>
            Although we use commercially reasonable efforts to provide
            individuals with access to their Personal Information and to respond
            to their requests, there may be circumstances in which we need to
            ask you for more information to identify your account and may ask
            you to demonstrate your identity or we may be unable to provide
            access, including but not limited to: where the information contains
            legal privilege, would compromise others’ privacy or other
            legitimate rights, where the burden or expense of providing access
            would be disproportionate to the risks to the Individual’s privacy
            in the case in question or where it is commercially proprietary. If
            we determine that access should be restricted in any particular
            instance, we will provide you with an explanation of why that
            determination has been made and a contact point for any further
            inquiries. To protect your privacy, we will take commercially
            reasonable steps to verify your identity before granting access to
            or making any changes to your Personal Information.
          </p>

          <p>
            <strong>1. Request of access.</strong> You have the right to request
            a copy of the Personal Information that we hold about you, as well
            as to inquire about the origin, recipients, and purposes of that
            data. There are certain exceptions to this right, however, so that
            we may deny access to you as required by law or to protect the legal
            rights of others.
          </p>

          <p>
            <strong>2. Request correction.</strong> You have the right to
            request correction of the Personal Information that we hold about
            you. This enables you to have any incomplete or inaccurate data we
            hold about you corrected, though we may need to verify the accuracy
            of the new data you provide to us. Our goal is to keep your Personal
            information accurate, current and complete. Please contact us if you
            believe your information is not accurate or changes. You may edit
            some of your account information via the Site.
          </p>

          <p>
            <strong>
              3. Right to Erasure and Deletion of Your Personal Information.
            </strong>{" "}
            You may have a legal right to request that we delete your Personal
            Information when it is no longer necessary for the purposes for
            which it was collected, or when, among other things, your Personal
            Information has been unlawfully processed.
          </p>

          <p>
            We also may decide to delete your Personal Information if we believe
            it is incomplete, inaccurate or that our continued storage of your
            Personal Information is contrary to our legal obligations or
            business objectives. When we delete Personal Information, it will be
            removed from our active servers and databases and our Services, but
            it may remain in our archives when it is not practical or possible
            to delete it. We may also retain your Personal Information as needed
            to comply with our legal obligations, resolve disputes, or enforce
            any agreements, such as limited information about your device that
            may be retained for the purposes of fraud detection and enforcement
            of our “Legal Documents”.
          </p>

          <p>
            <strong>4. Right to Object.</strong> In certain circumstances, as
            permitted under applicable law, you have the right to object to
            processing of your Personal Information and to ask us to erase or
            restrict our use of your Personal Information. If you would like us
            to stop using your Personal Information, please contact us and we
            will let you know if we are able to agree to your request.
          </p>

          <p>
            <strong>5. Right to Portability.</strong> You have the right to
            request that some of your Personal Information is provided to you or
            an authorized third party in a commonly-used, machine-readable
            format (such as .txt). If you require the direct transfer of data to
            another responsible party, this will only be done to the extent
            technically feasible.
          </p>

          <p>
            <strong>6. Right to Withdraw Consent.</strong> If you have provided
            your consent to the collection, processing and transfer of your
            Personal Information, you have the right to fully withdraw your
            consent. We attempt to comply with all reasonable and legitimate
            requests. As discussed above, you may modify the permissions and
            settings on your device and the Site to limit access to certain
            information. To withdraw your consent with respect to emails and
            other communications from us, please notify us or follow the opt-out
            links available in the communications sent to you. Once we have
            received notice that you have withdrawn your consent, we will no
            longer process your information for the purpose(s) to which you
            originally consented unless there are compelling legitimate grounds
            for further processing that override your interests, rights and
            freedoms or for the establishment, exercise or defense of legal
            claims. Withdrawal of consent to receive marketing communications
            will not affect the processing of Personal Information for the
            provision of our Services. However, changing the permissions and
            settings on your device or within the Site may cause certain
            features of the Site to stop functioning.
          </p>

          <p>
            <strong>7. Updating Your Personal Information.</strong> If any of
            the Personal Information you have provided to us changes, please let
            us know. For instance, if your email changes, you wish to cancel any
            request you have made of us, or if you become aware of inaccurate
            Personal Information about you, please contact us to update your
            information. You may edit your account information via the Site.
          </p>

          <p>
            We are not responsible for any losses arising from any inaccurate,
            inauthentic, deficient or incomplete Personal Information that you
            provide to us.
          </p>
        </section>
        <section>
          <h2>Limiting Collection</h2>
          <p>
            The Company limits the collection of Personal Information by
            collecting only the information required to fulfill the identified
            purposes. We will collect only the minimum amount of information
            required to facilitate the Services. However, we may provide you
            with the option of sharing additional information to enhance your
            use of the Services. We will share your personal information with
            third parties only in the ways that are described in this Privacy
            Policy.
          </p>

          <p>
            You may in connection with some but not all of our Services have the
            ability to allow others (Guest Collaborators) access to your
            account. Note that by providing Guest Collaborators access to your
            account, you will also be providing the Guest Collaborator access to
            any Personal Information in your account. While the Guest
            Collaborator must agree to our “Legal Documents”, we take no
            responsibility for any collection, use or disclosure of your
            Personal Information by your Guest Collaborator. It is your
            responsibility to ensure that your Guest Collaborator complies with
            privacy standards that are no less stringent than our own.
          </p>

          <p>
            When you use certain Services, you have the option to share
            information with other accounts you have signed up for. You may
            choose from time to time to connect the Company with other
            third-party service providers in order to utilize these services in
            conjunction with the Company Apps. Purposes may include saving
            copies of records, importing data from external sources,
            communications with third parties, and conducting payment and direct
            deposit functions, among others. In such instances, your account and
            password credentials and other required information in respect of
            such third-party service may be stored by the Company for the
            purpose of providing this service.
          </p>
        </section>
        <section>
          <h2>Limiting Use, Disclosure and Retention</h2>
          <p>
            The Company will not use or disclose Personal Information for
            purposes other than the identified purposes of the Services or such
            other purposes which we identify from time to time, such as to carry
            out treatment (the provision, coordination, or management of health
            care and related services by one or more health care providers,
            including the coordination or management of health care with a third
            party, consultation between health care providers relating to a
            patient; or the referral of a patient for health care from one
            provider to another), payment (the activities of a health care
            provider to obtain reimbursement for the provision of health care,
            including determinations of eligibility or coverage, billing, and
            review of services with respect to medical necessity or
            appropriateness of care), and health care operations (conducting
            quality assessment and improvement activities, reviewing the
            competence of health care professionals, conducting training
            programs, conducting or arranging for medical review, legal services
            and auditing functions, business management and planning, among
            other).
          </p>

          <p>
            We also ensure that only those employees responsible for the
            Services' operations have physical or technical access to Personal
            Information and only where such access is required to perform work
            authorized by their supervisors.
          </p>

          <p>
            We retain all Personal Information provided by you on secure
            servers, as do our third-party partners and service providers. This
            data will be subject to the laws of the relevant jurisdictions. Our
            third-party service providers, including financial institutions,
            have their own “Legal Documents” that will govern your relationship
            with these third parties. You are responsible for reviewing and
            agreeing to these “Legal Documents”. If you do not agree with these
            “Legal Documents” you should not use the services offered by such
            third-party service providers.
          </p>

          <p>
            In the future we may collect information, including Personal
            Information, to enable you to access services provided by our
            third-party partners and service providers. You will be subject to
            the “Legal Documents” of these third-party service providers. You
            are responsible for reviewing and agreeing to these “Legal
            Documents”. If you do not agree with the “Legal Documents” you
            should not use the services offered by such third-party service
            providers.
          </p>

          <p>
            When the Company services some data is cached in the memory of the
            device. If the application is put in the background without closing,
            the data may remain in the cache. We take no responsibility for any
            unauthorized viewing of this data by third parties.
          </p>
        </section>
        <section>
          <h2>Children’s Privacy</h2>
          <p>
            If you learn that a minor has provided us with personal information
            without a lawful guardian or parent’s lawful consent, you may alert
            us at{" "}
            <a href="mailto:compliance@UltimedPharmacy.com">
              compliance@UltimedPharmacy.com
            </a>
            .
          </p>

          <p>
            If we learn that we have unlawfully collected any personal
            information from minors, we will promptly take steps to delete such
            information and terminate the minor’s user account.
          </p>
        </section>
        <section>
          <h2>Accuracy</h2>
          <p>
            The Company relies on you to ensure that the Personal Information
            you provide to us while using the Services is as accurate, complete
            and up-to-date as necessary for the purposes for which it is to be
            used. Upon request the Company will provide you with information
            about whether we hold any of your Personal Information. You are
            welcome to make changes, request deletion or corrections to Personal
            Information at any time by contacting us at the contact information
            listed below. We will respond to your request within a reasonable
            timeframe.
          </p>
          <p>
            We also make every effort to ensure the accuracy of the information
            in our reports, displays, articles and support queries. However, you
            must verify all information created from your use of the Services
            and we recommend that you consult a professional before completing
            any government or regulatory filing or otherwise relying upon the
            information, as the use of this information is at your own risk.
          </p>
          <p>
            You are responsible for ensuring that the information you have
            provided is truthful, accurate, reliable and complete.
          </p>
          <h2>Payment Card Information</h2>
          <p>
            To ensure the security of your and your customers’ credit card data,
            the Company does not store credit card numbers. For payment
            processing purposes, the Company uses a third-party service provider
            named Accepta Payments. For more information about Accepta Payments,
            including their terms of use and privacy policies, please visit:{" "}
            <a href="https://acceptapayments.com">
              https://acceptapayments.com
            </a>
            .
          </p>
        </section>
        <section>
          <h2>Openness</h2>
          <p>
            The Company makes clear and transparent notice of its privacy
            practices publicly available. Our notices describe the collection,
            use, disclosure, and protection of Personal Information and provide
            the contact information of our privacy contact person.
          </p>

          <p>
            The Company will advise you at the first reasonable opportunity upon
            discovering or being advised of an incident where your Personal
            Information is lost, stolen, accessed, used, disclosed, copied,
            modified or disposed of by unauthorized persons or in an
            unauthorized manner. In the event that PHI is accessed, used,
            disclosed, copied, modified, stolen or disposed of by unauthorized
            persons we will notify you in accordance with HIPAA Breach
            Notification Rule, 45 CFR §§ 164.400-414.
          </p>
        </section>
        <section>
          <h2>Additional Features</h2>
          <p>
            The Site may offer publicly accessible blogs and community forums.
            You should be aware that any information you provide in these areas
            may be read, collected, and used by others who access them. We take
            no responsibility for any information which you or your employees,
            agents, contractors, and representatives post or publish on the
            Site, and you agree to indemnify and hold the Company harmless for
            any loss, cost, complaint, damage, claim or liability whatsoever
            arising from any such post or publication.
          </p>

          <p>
            Some of the functionality of the Services and the Site interoperate
            with, and are highly dependent upon, application programming
            interfaces (APIs) from third parties. We may collect Personal
            Information from these third-party APIs to the extent you authorize
            us to do so, and our collection, use and disclosure of that Personal
            Information will be governed by this privacy policy.
          </p>

          <p>
            Our Site may include links to other websites whose privacy practices
            may differ from those of the Company. If you submit personal
            information to any of those sites, your information is governed by
            their privacy policies. We encourage you to carefully read the
            privacy policy of any website you visit.
          </p>
        </section>
        <section>
          <h2>Behavioral Targeting/Re-Targeting</h2>
          <p>
            We may partner with a third party to either display advertising on
            our Site or to manage our advertising on other sites. Our
            third-party partner may use technologies such as cookies to gather
            information about your activities on this site and other sites in
            order to provide you advertising based upon your browsing activities
            and interests.
          </p>
        </section>

        <section>
          <h2>Agents/Service Providers</h2>
          <p>
            The Company uses third-party email services providers in order to
            provide certain services to help us run our operations, including,
            without limitation, for purposes of delivering emails to you. If
            required, we will disclose your Personal Information to these
            service providers to the extent required by us to receive these
            services.
          </p>
        </section>

        <section>
          <h2>Notification of Privacy Policy Changes</h2>
          <p>
            We may update this privacy policy to reflect changes to our
            information practices without prior notification. You are bound by
            any changes to the Privacy Policy when you use the Services after
            such changes have been first posted. We encourage you to
            periodically review our Privacy Policy for the latest information on
            our privacy practices.
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
