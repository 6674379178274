import React from "react";
import image from "../assets/term-image.png";

const TermsOfService = () => {
  return (
    <div className="terms-of-servicse">
      <div className="hero-section2">
        {/* <img src={image} alt="" className="main-image" /> */}
        <div className="main-content">
          <div className="overlay">
            <p className="text-logo">Terms of Service </p>
          </div>
        </div>{" "}
      </div>
      <div className="terms">
        <section>
          <h2>Agreement</h2>
          <p>
            This is an agreement between you or the entity that you represent
            (hereinafter “User”, “you” or “your”) and Physician Management Corp.
            of America, including its subsidiaries and/or related/affiliated
            entities (hereinafter the “Company”) governing your use of Ultimed Pharmacy Mobile App
            Portal and/or services ("Ultimed Pharmacy Mobile App").
          </p>
          <p>
            We welcome you as a Ultimed Pharmacy Mobile App User. Before you use our services, we
            ask you to carefully read and agree to the following terms and
            conditions (“Terms of Use”).
          </p>
        </section>

        <section>
          <h2>Terms of Use</h2>
          <p>
            These Terms of Use govern the use of Ultimed Pharmacy Mobile App. Your access to Ultimed Pharmacy Mobile App
            in any way is evidence of your acceptance of the Terms of Use and
            the enforcement of said Terms of Use. If you do not agree to these
            Terms of Use, you may not use Ultimed Pharmacy Mobile App.
          </p>
          <p>
            The Company reserves the right, in its sole discretion, to change,
            modify, or otherwise alter these Terms of Use at any time upon
            posting the modified Terms of Use on Ultimed Pharmacy Mobile App. Please review the
            Terms of Use periodically. Your continued use of Ultimed Pharmacy Mobile App, after such
            posting or notification means you accept the modifications. The use
            by you of any content or services accessible through Ultimed Pharmacy Mobile App may be
            subject to your acceptance of separate agreements with the Company
            or third parties.
          </p>
        </section>

        <section>
          <h2>User Account</h2>
          <h3>License and Ultimed Pharmacy Mobile App Access</h3>
          <p>
            The Company grants you a limited license to access and make personal
            use of Ultimed Pharmacy Mobile App. You may not download (other than page caching), or
            modify any portion of it, except with the express written or
            e-mailed consent of the Company. The license to use Ultimed Pharmacy Mobile App does not
            include any resale or commercial use, derivative use, or any use of
            data mining, robots or similar data gathering and extraction tools
            or processes. You agree not to store in any form, distribute,
            transmit, display, reproduce, modify, create derivative works from,
            sell or otherwise exploit any of the content on Ultimed Pharmacy Mobile App for any
            commercial purpose. By using Ultimed Pharmacy Mobile App, you warrant to the Company
            that you will not use Ultimed Pharmacy Mobile App, or any of the content obtained from
            Ultimed Pharmacy Mobile App, for any purpose that is unlawful or prohibited by these
            Terms of Use. The Company does not grant any license or other
            authorization to any user of its trademarks, copyrights and/or any
            other intellectual property, by placing them on Ultimed Pharmacy Mobile App. If you
            violate any of these Terms of Use, your permission to use Ultimed Pharmacy Mobile App
            automatically terminates.
          </p>
          <p>
            You may not attempt to gain unauthorized access to any portion or
            feature of Ultimed Pharmacy Mobile App, or any other systems or networks connected to
            Ultimed Pharmacy Mobile App or to any of the Company’s server, or to any of the services
            offered on or through Ultimed Pharmacy Mobile App, by hacking, password "mining" or any
            other means.
          </p>
          <p>
            In consideration of your use of Ultimed Pharmacy Mobile App, you agree to: (a) provide
            true, accurate, current and complete information about yourself as
            prompted by the registration form, if applicable, and (b) to
            maintain and update this information to keep it true, accurate,
            current and complete. If any information provided by you is untrue,
            inaccurate, not current or incomplete, the Company has the right to
            terminate your account and refuse any and all current or future use
            of Ultimed Pharmacy Mobile App. You agree not to sublicense, resell or transfer the use
            or access to Ultimed Pharmacy Mobile App, unless expressly authorized in writing by a
            duly authorized officer of the Company.
          </p>
        </section>

        <section>
          <h2>Mobile Devices</h2>
          <p>
            The use of Ultimed Pharmacy Mobile App may be available through compatible mobile
            devices and may require a data plan. You agree that you are solely
            responsible for any such requirements, including any applicable fees
            as well the terms of use of your agreement with your mobile device
            service provider.
          </p>
          <p>
            You understand and agree that not all the features and functionality
            may be available on compatible mobile devices.
          </p>
          <p>
            You further understand and agree that access to Ultimed Pharmacy Mobile App is subject
            to your mobile device service provider network availability and the
            Company is not responsible for the availability of services from
            your provider, including any loss, damage, error, or failure to
            transmit.
          </p>
        </section>

        <section>
          <h2>Text Messages</h2>
          <p>
            If you have opted-in to receive text messages from us, the following
            Terms of Use and conditions apply:
          </p>
          <ul>
            <li>
              You may text STOP at any time to stop receiving messages from us.
            </li>
            <li>
              To receive information on how to access customer support or you
              may email to: info@medidohealth.com
            </li>
            <li>
              Message and Data Rates may apply to any messages you send or
              receive from us.
            </li>
            <li>
              You can expect to receive up to two messages from us when you opt
              in to a program: one after you have opted in to the program and
              another confirming your enrollment.
            </li>
          </ul>
        </section>

        <section>
          <h2>Electronic Communications</h2>
          <p>
            When you visit Ultimed Pharmacy Mobile App or send e-mails to us, you are communicating
            with us electronically. You consent to receive communications from
            us electronically. We will communicate with you by e-mail or by
            posting notices on Ultimed Pharmacy Mobile App. You agree that all agreements, notices,
            disclosures and other communications that we provide to you
            electronically satisfy any legal requirement that such communication
            be in writing.
          </p>
          <p>
            You are responsible for providing, at your expense, any access to
            the Internet and any required equipment.
          </p>
        </section>

        <section>
          <h2>Acceptable Use Policy</h2>
          <p>
            This policy establishes requirements for the use of Ultimed Pharmacy Mobile App to
            ensure the provision of medication information and profiles, recipe
            history, Kardex Registry, and patients reports.
          </p>
          <p>
            By using Ultimed Pharmacy Mobile App you agree that you will not do any of the
            following:
          </p>
          <ol>
            <li>
              Restrict or inhibit any other user from using and enjoying
              Ultimed Pharmacy Mobile App; or
            </li>
            <li>
              Upload any political, unlawful, fraudulent, libelous, defamatory,
              obscene, pornographic, profane, threatening, abusive, hateful,
              offensive, or otherwise objectionable information of any kind,
              including without limitation any transmissions constituting or
              encouraging conduct that would constitute a criminal offense, give
              rise to civil liability, or otherwise violate any local, state,
              national or foreign law, including without limitation the U.S.
              export control laws and regulations; or
            </li>
            <li>
              Upload any advertisements, solicitations, chain letters, pyramid
              schemes, investment opportunities or schemes or other unsolicited
              commercial communication (except as otherwise expressly permitted
              by the Company) or engage in spamming or flooding; or
            </li>
            <li>
              Upload any information or software which contains a virus, trojan
              horse, worm or other harmful component; or
            </li>
            <li>
              Post, publish, transmit, reproduce, distribute or in any way
              exploit any information, software or other material obtained
              through Ultimed Pharmacy Mobile App for commercial purposes (other than as expressly
              permitted by the provider of such information, software or other
              material); or
            </li>
            <li>
              Upload, post, publish, transmit, reproduce, or distribute in any
              way, information, software or other material obtained through
              Ultimed Pharmacy Mobile App which is protected by copyright or other proprietary
              right, or derivative works with respect thereto, without obtaining
              permission of the copyright owner or right holder.
            </li>
          </ol>
          <p>
            The Company has no obligation to monitor Ultimed Pharmacy Mobile App. However, you
            acknowledge and agree that the Company has the right to monitor
            Ultimed Pharmacy Mobile App electronically from time to time and to disclose any
            information as necessary or appropriate to satisfy any law,
            regulation or other governmental request, proper operation, or to
            protect itself or its subscribers.
          </p>
          <p>
            The Company will not intentionally monitor or disclose any private
            electronic-mail message unless required by law. The Company reserves
            the right to refuse to post or to remove any information or records,
            in whole or in part, that, in its sole discretion, are unacceptable,
            undesirable, inappropriate or in violation of these Terms of Use.
          </p>
        </section>
        <section>
          <h2>Feedback</h2>
          <p>
            The Company may provide you with a mechanism to provide feedback,
            suggestions, and ideas, if you choose, about Ultimed Pharmacy Mobile App. You agree that
            the Company may, in its sole discretion, use the feedback you
            provide in any way, including in future modifications of Ultimed Pharmacy Mobile App
            and/or advertising and promotional materials relating thereto. You
            hereby grant the Company a perpetual, worldwide, fully transferable,
            irrevocable, royalty-free license to use, reproduce, modify, create
            derivative works from, distribute, and display the feedback in any
            manner and for any purpose.
          </p>
        </section>

        <section>
          <h2>Third Party Services</h2>
          <p>
            In connection with your use of Ultimed Pharmacy Mobile App, you may be made aware of
            services, products, offers, and promotions provided by third
            parties, and not by the Company. If you decide to use third-party
            services, you are responsible for reviewing and understanding the
            terms and conditions governing any third-party services. You agree
            that the third party, and not the Company, is responsible for the
            performance of the third-party services.
          </p>
          <p>
            Among the third parties authorized to provide services in connection
            with your use of Ultimed Pharmacy Mobile App are:
          </p>
          <ul>
            <li>Physician Management Corporation of America;</li>
            <li>Specialty Solutions Inc;</li>
            <li>Medido Health LLC;</li>
          </ul>
          <p>We reserve the right to include others in the future.</p>
        </section>

        <section>
          <h2>Third Party Websites</h2>
          <p>
            Ultimed Pharmacy Mobile App may contain or reference links to websites operated by a
            third party ("Third Party Websites"). These links are provided as a
            convenience only. Such Third Party Websites are not under the
            control of the Company. The Company is not responsible for the
            content or link contained in any Third Party Websites. The Company
            does not review, approve, monitor, endorse, warrant, or make any
            representations with respect to Third Party Websites, and the
            inclusion of any link in Ultimed Pharmacy Mobile App is not and does not imply an
            affiliation, sponsorship, endorsement, approval, investigation,
            verification, or monitoring by the Company of any information
            contained in any Third Party Websites. In no event will the Company
            be responsible for the information contained in such Third Party
            Websites or for your use of or inability to use such websites.
            Access to any Third Party Websites is at User's own risk, and User
            acknowledges and understands that linked Third Party Websites may
            contain terms of use and privacy policies that are different from
            those of the Company. The Company is not responsible for such
            provisions and expressly disclaims any liability for them. You
            should review the applicable terms of use and policies, including
            privacy and data gathering practices, of any Third Party Websites.
          </p>
        </section>

        <section>
          <h2>Additional Terms of Use</h2>
          <h3>Disclaimer of Warranties</h3>
          <p>
            Your use of Ultimed Pharmacy Mobile App, including any applets, software, and content
            contained therein, is entirely at your own risk. THE SERVICE IS
            PROVIDED "AS IS," AND TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
            LAW, THE COMPANY, ITS AFFILIATES, LICENSORS, THIRD-PARTY CONTENT OR
            SERVICE PROVIDERS, DISTRIBUTORS, DEALERS AND SUPPLIERS (COLLECTIVELY
            "SUPPLIERS") DISCLAIM ALL GUARANTEES AND WARRANTIES, WHETHER
            EXPRESS, IMPLIED, OR STATUTORY REGARDING THE USE OF Ultimed Pharmacy Mobile App AND
            RELATED MATERIALS INCLUDING ANY WARRANTY FOR A PARTICULAR PURPOSE,
            TITLE, MERCHANTABILITY AND NON-INFRINGEMENT. THE COMPANY DOES NOT
            WARRANT OR GUARANTEE THE ACCURACY, RELIABILITY, COMPLETENESS,
            USEFULNESS, NON-INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS, OR
            QUALITY OF ANY CONTENT ON Ultimed Pharmacy Mobile App, REGARDLESS OF WHO ORIGINATES THAT
            CONTENT. THE COMPANY DOES NOT WARRANT THAT Ultimed Pharmacy Mobile App IS SECURE, FREE
            FROM BUGS, VIRUSES, INTERRUPTION, ERRORS, THEFT OR DESTRUCTION OR
            THAT Ultimed Pharmacy Mobile App WILL MEET YOUR REQUIREMENTS. YOU EXPRESSLY UNDERSTAND
            AND AGREE THAT YOU BEAR ALL RISKS ASSOCIATED WITH USING OR RELYING
            ON THAT CONTENT. THE COMPANY IS NOT LIABLE OR RESPONSIBLE IN ANY WAY
            FOR ANY CONTENT POSTED ON OR LINKED TO Ultimed Pharmacy Mobile App, INCLUDING, BUT NOT
            LIMITED TO, ANY ERRORS OR OMISSIONS IN CONTENT, OR FOR ANY LOSSES OR
            DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF OR RELIANCE ON
            ANY CONTENT.
          </p>

          <h3>Limitation of Liability</h3>
          <p>
            THE ENTIRE CUMULATIVE LIABILITY OF THE COMPANY AND ITS SUPPLIERS FOR
            ALL MATTERS ARISING FROM OR RELATING TO THESE TERMS OF USE SHALL BE
            LIMITED TO THE AMOUNT PAID BY THE CUSTOMER FOR THE RELATED SERVICES
            OR CONTENT PURCHASED FROM THE COMPANY, ITS AUTHORIZED RESELLER OR
            ITS SERVICE PROVIDERS. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
            LAW, THE COMPANY AND ITS SUPPLIERS SHALL NOT BE LIABLE FOR ANY
            INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES OR
            FOR DAMAGES RELATING TO TELECOMMUNICATION FAILURES, LOSS,
            CORRUPTION, SECURITY OR THEFT OF DATA, VIRUSES, SPYWARE, OR THE
            LIKE, WHETHER BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE),
            PRODUCT LIABILITY OR OTHERWISE, EVEN IF THE COMPANY HAS BEEN ADVISED
            OF THE POSSIBILITY OF SUCH DAMAGES AND EVEN IF A REMEDY SET FORTH
            HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. THE
            LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF
            THE BASIS OF THE BARGAIN BETWEEN THE COMPANY AND YOU. THE COMPANY
            WOULD NOT BE ABLE TO HAVE PROVIDED THE SERVICE WITHOUT SUCH
            LIMITATIONS.
          </p>

          <h3>Termination</h3>
          <p>
            Your privilege to use or access Ultimed Pharmacy Mobile App may be terminated by the
            Company immediately and without notice if you fail to comply with
            any term or condition of the Terms of Use. Upon such termination,
            you must immediately cease accessing or using Ultimed Pharmacy Mobile App and agree not
            to re-register or otherwise make any use of Ultimed Pharmacy Mobile App whatsoever.
            Furthermore, you acknowledge that the Company reserves the right to
            take action -- technical, legal or otherwise -- to block, nullify or
            deny your ability to access Ultimed Pharmacy Mobile App. You understand that the Company
            may exercise this right in its sole discretion.
          </p>

          <h3>Support or Maintenance</h3>
          <p>
            The Company will perform four (4) maintenance events (“Maintenance
            Events”) to Ultimed Pharmacy Mobile App per year. Each Maintenance Event can take up to
            six (6) hours to be concluded. Therefore, the Company guarantees an
            availability of 98% of the year’s term.
          </p>

          <h3>Modification of Ultimed Pharmacy Mobile App</h3>
          <p>
            The Company reserves the right, in its sole discretion, at any time
            and from time to time to change, modify or discontinue, temporarily
            or permanently, Ultimed Pharmacy Mobile App (or any part thereof). The Company shall not
            be liable to you or other third parties for any such modification,
            suspension or discontinuance except as expressly provided herein.
          </p>

          <p>
            You agree to defend, indemnify and hold the Company and its
            affiliates harmless from any and all claims, liabilities, costs and
            expenses, including reasonable attorneys' fees, arising in any way
            from your use of Ultimed Pharmacy Mobile App or the placement or transmission of any
            message, information, software or other materials through Ultimed Pharmacy Mobile App by
            you or users of your account or related to any violation of these
            Terms of Use.
          </p>

          <h3>Geographic Restrictions</h3>
          <p>
            The owner of these Websites is based in Puerto Rico. We provide
            Ultimed Pharmacy Mobile App for use only by persons located in Puerto Rico and the
            United States of America. We make no claims that Ultimed Pharmacy Mobile App or any of
            their content is accessible or appropriate outside of Puerto Rico
            and the United States of America.
          </p>
        </section>

        <section>
          <h2>Cookies Statement</h2>
          <p>
            Ultimed Pharmacy Mobile App uses cookies and other related technologies (for convenience
            all technologies are referred to as "cookies"). Cookies are also
            placed by third parties we have engaged. In the document below we
            inform you about the use of cookies on Ultimed Pharmacy Mobile App.
          </p>
          <p>
            A cookie is a small simple file that is sent along with pages of
            Ultimed Pharmacy Mobile App and stored by your browser on the hard drive of your
            computer or another device. The information stored therein may be
            returned to our servers or to the servers of the relevant third
            parties during a subsequent visit.
          </p>
          <p>
            Many browsers can notify you when you receive a cookie, giving you
            the option to accept it or not.
          </p>
          <p>
            By not accepting cookies, you may not be permitted to access certain
            information and Ultimed Pharmacy Mobile App may not display properly.
          </p>
          <p>
            When you visit Ultimed Pharmacy Mobile App, the following categories of cookies will be
            set in your browser:
          </p>
          <ul>
            <li>
              <strong>Strictly necessary cookies:</strong> These cookies are
              essential in order to enable you to move around Ultimed Pharmacy Mobile App and use
              its features. Without these cookies services you have asked for
              cannot be provided.
            </li>
            <li>
              <strong>Performance cookies:</strong> These cookies collect
              information so that we can analyze how our visitors use Ultimed Pharmacy Mobile App.
              These cookies do not collect information that identifies you. All
              information these cookies collect is anonymous and is only used to
              improve how Ultimed Pharmacy Mobile App works.
            </li>
            <li>
              <strong>Functional cookies:</strong> These cookies allow Ultimed Pharmacy Mobile App
              to remember choices you make (e.g., such as your username or the
              region you are in) and provide enhanced, more personal features.
              We may use information collected from functional cookies to
              identify user behavior and to serve content based on your user
              profile. These cookies cannot track your browsing activity outside
              Ultimed Pharmacy Mobile App. They do not gather any information about you that could
              be used for advertising or to record where you have been on the
              internet outside Ultimed Pharmacy Mobile App.
            </li>
            <li>
              <strong>Analytics cookies:</strong> In order to keep Ultimed Pharmacy Mobile App
              relevant, easy to use and up-to-date, we use analytics services to
              help us understand how people use Ultimed Pharmacy Mobile App.
            </li>
          </ul>
          <p>
            A script is a piece of program code that is used to make Ultimed Pharmacy Mobile App
            function properly and interactively. This code is executed on our
            server or on your device.
          </p>
          <p>
            A web beacon (or a pixel tag) is a small, invisible piece of text or
            image on Ultimed Pharmacy Mobile App that is used to monitor traffic on Ultimed Pharmacy Mobile App. In
            order to do this, various data about you is stored using web
            beacons.
          </p>
          <p>
            When you visit Ultimed Pharmacy Mobile App for the first time, you consent to us using
            all cookies, scripts, web beacons, and plug-ins as described in this
            cookie statement. You can disable the use of cookies via your
            browser, but please note that Ultimed Pharmacy Mobile App may no longer work properly.
          </p>
          <p>
            You have the following rights with respect to your personal data:
          </p>
          <ul>
            <li>
              You have the right to know why your personal data is needed, what
              will happen to it, and how long it will be retained for.
            </li>
            <li>
              You have the right to access your personal data that is known to
              us.
            </li>
            <li>
              You have the right to supplement, correct, have deleted or blocked
              your personal data whenever you wish, as long as it is not
              prohibited under applicable state and federal laws.
            </li>
            <li>
              You have the right to revoke the consent and to have your personal
              data deleted, as long as it is not prohibited under applicable
              state and federal laws.
            </li>
          </ul>
          <p>
            To exercise these rights or have a complaint about how we handle
            your data, please contact us.
          </p>
          <p>
            You can use your internet browser to automatically or manually
            delete cookies. You can also specify that certain cookies may not be
            placed. Another option is to change the settings of your internet
            browser so that you receive a message each time a cookie is placed.
            For more information about these options, please refer to the
            instructions in the Help section of your browser.
          </p>
          <p>
            Please note that Ultimed Pharmacy Mobile App may not work properly if all cookies are
            disabled. If you do delete the cookies in your browser, they will be
            placed again after your consent when you visit Ultimed Pharmacy Mobile App again.
          </p>
          <p>
            For questions and/or comments about our cookie policy and this
            statement, please contact us by email at:{" "}
            <a href="mailto:info@medidohealth.com">
              info@medidohealth.com
            </a>
          </p>
        </section>
        <section>
          <h2>Intellectual Property Rights</h2>
          <p>
            The Company trademarks are valuable assets of the Company and are
            legally protected in Puerto Rico and the United States. This Terms
            of Use provides general guidelines regarding the proper, permitted
            and prohibited use of the Company's trademarks. The Terms of Use
            does not constitute a license, legal opinion, and/or legal advice,
            and is subject to change without notice. Trademarks not owned by the
            Company are the property of their respective holders.
          </p>

          <p>
            The Company reserves all rights with respect to its trademarks,
            service marks, logos, trade names, trade dress and any other
            intellectual property ("Trademarks"). The Company does not routinely
            accept or review specific requests for guidance on the use of its
            Trademarks. Any unauthorized use or any use of a trademark that is
            confusingly similar to, or likely to cause confusion, would
            constitute infringement of the Trademarks’ rights. This document
            does NOT constitute any form of license to use any of the
            Trademarks. No Company employee is authorized to provide guidance on
            the use of the Trademarks, nor to give permission to use any
            Trademarks, except by formal written license agreement with the
            Company. Any promise, agreement, guidance or other representation by
            any Company employee (other than by a formal written license as
            described herein) is invalid and cannot be relied upon. If you have
            questions about the laws surrounding the use of the Trademarks, or
            any other parties' marks, you should consult a qualified attorney.
          </p>

          <p>
            This list of some trademarks is by no means an exhaustive list and
            does not in any way limit the number of trademarks in which the
            Company claims ownership or the scope of Company's claims.
          </p>

          <p>
            This list merely provides a guide to proper symbol placement and
            spelling for the Trademarks:
          </p>
          <ul>
            <li>UltimedPharmacy MobileApp</li>
            <li>UltimedPharmacy</li>
            <li>Medpak</li>
            <li>Medcycle</li>
            <li>Meducate</li>
            <li>Medvax</li>
            <li>Medmatch</li>
            <li>ME</li>
          </ul>

          <p>
            You may make purely referential use of the Trademarks as described
            in this section, but you should consult your own legal counsel on
            what does or does not constitute a fair use (descriptive or
            nominative) of another company's trademarks, and what may constitute
            an unauthorized or infringing use. When referencing the Trademarks,
            use of the Company corporate logo, product logos or box designs is
            NOT allowed without a written license from the Company. Referential
            uses of the Trademarks must only be made in plain text.
          </p>

          <p>
            Plain-text use of the Trademarks in commercial contexts may only be
            made for purposes like describing the ability to train others on the
            use of Company products (in the case of such services) or true,
            factual statements as to the nature of any relationship with the
            Company, where one exists. Any use of Trademarks or of statements
            that imply endorsement, affiliation, certification or other
            relationship - where none exists - are strictly prohibited. There
            may be situations where it would be appropriate for you to state in
            your marketing materials, or on packaging for your own offerings,
            that your product or service is "for use with" or is "designed to
            work with" or "compatible with" a Company product. Please consult a
            qualified attorney if you have questions about proper nominative
            fair use of the Trademarks.
          </p>

          <p>
            Use of the Trademarks within your companies' product names, service
            names, company/trade names, DBA's, domain names or other indicia is
            strictly prohibited.
          </p>

          <p>
            Referential use of the Trademarks should include the proper
            trademark symbol(s). Where possible, Company requests that those who
            make reference to Company's products with their associated
            trademarks also provide a short ownership attribution statement
            somewhere within those materials. Typically, the attribution
            statement is displayed at the end of the material, in the footer of
            the document or site and should read: “Trademark and Trademark’s
            logo are trademarks or registered trademarks of the Company. Any use
            without the express written consent of the Company is forbidden.”
          </p>

          <p>
            Trademarks are adjectives and should generally be used in connection
            with the generic nouns they modify. Since Trademarks are adjectives,
            they should not be used as nouns or verbs.
          </p>
        </section>

        <section>
          <h2>Patents</h2>
          <p>
            Features and services within the Company products may be the subject
            matter of pending or issued patents.
          </p>
        </section>

        <section>
          <h2>Screen Shots</h2>
          <p>
            Screen shots are the individual screen displays contained within
            Company applications and online services. You may make fair use of a
            limited number of screen shots for purely illustrative purposes
            provided you adhere to the following guidelines:
          </p>
          <ol>
            <li>
              Your use may not directly or indirectly state or imply
              sponsorship, affiliation, or endorsement of your product or
              service by/with the Company.
            </li>
            <li>
              You may not alter the screen shot in any way except to resize the
              screen shot in direct proportion to the original. Screen shots
              must be reproduced in their entirety.
            </li>
            <li>
              You may add commentary or other text analysis as long as it is
              very clearly attributable to you, and not to the Company.
            </li>
            <li>
              You may not use screen shots from the Company products or other
              products that are subject to non-disclosure laws and/or
              obligations, and/or that have not yet been commercially released
              by the Company.
            </li>
            <li>
              You may not use screen shots that contain third-party content
              unless you separately seek and obtain permission from such third
              party to display its content.
            </li>
            <li>
              You must include the following copyright attribution statement on
              all materials containing the Company screen shots: "Reprinted with
              permission © Company. All rights reserved." (Example: "Reprinted
              with permission © Ultimed Pharmacy Mobile App. All rights reserved.")
            </li>
            <li>
              If your materials include references to a Company product, the
              full name of the product with corresponding trademark symbol
              should be shown in plain text at the first and/or most prominent
              mention in such materials. When referencing any Company
              trademarks, please make sure your use complies with Trademarks
              restrictions.
            </li>
            <li>
              Your use of Company's screen shots may not be incorporated into
              political, obscene or pornographic material, and may not, in
              Company's sole opinion, be disparaging, defamatory, or libelous to
              the Company, any of its products, or any other person or entity.
            </li>
            <li>
              Your materials should not be mostly or solely composed of Company
              screen shots or intellectual property. If Company screen shots or
              intellectual property would represent the majority of the material
              you wish to reproduce and/or distribute, you should first write to
              the Company seeking permission before publishing or distributing
              such content. Requests must be in writing and should be sent to
              the Company via e-mail{" "}
              <a href="mailto:info@medidohealth.com">info@medidohealth.com</a>
            </li>
          </ol>
          <p>
            If your use meets all the above criteria, no written permission is
            required. All other use of the Company screen shots requires prior
            written permission from the Company, including but not limited to
            any proposed use of screen shots in or for advertising, video,
            television, movies or commercials. If further written permission is
            required, please send a letter detailing your request to the Company
            via the “Contact Us” section. Please allow a minimum of two (2)
            weeks for your request to be processed.
          </p>

          <p>
            Company does not object to your use of short portions of
            Company-authored user guides for purely educational/instructional
            purposes only, provided you adhere to all of the following
            guidelines:
          </p>
          <ol>
            <li>
              Your use may not directly or indirectly imply sponsorship,
              affiliation, or endorsement of your product or service by/with the
              Company.
            </li>
            <li>
              You may not separately sell for a profit any portion of any
              Company-authored user guide.
            </li>
            <li>
              You may not alter a Company-authored user guide or any material
              contained therein in any way.
            </li>
            <li>
              Your use may not be incorporated with or into any political,
              obscene or pornographic material, and may not be disparaging,
              defamatory, or libelous to the Company, any of its products, or
              any other person or entity.
            </li>
          </ol>
          <p>
            If your use meets the above criteria, no written permission is
            required. All other use of Company-Authored user guides requires
            prior written permission from the Company. If further written
            permission is required, send a letter detailing your request to the
            Company via the “Contact Us” section. Please allow a minimum of two
            (2) weeks for your request to be processed.
          </p>
        </section>

        <section>
          <h2>Copyrights</h2>
          <p>
            The copyright laws give Company (as the copyright owner) the
            exclusive right to reproduce, prepare derivative works, distribute,
            perform, and publicly display a copyright work. The laws are
            intended to protect the owner’s intellectual property in the work,
            and to protect print and electronic works, such as publications,
            books, web-based applications, videos, and software. Works are
            protected even if they are not registered with the U.S. Copyright
            Office, or do not carry the copyright symbol “©”. Generally, copying
            of copyrighted materials is not allowed, whether done by
            photocopying or electronic means (e.g., e-mailing or faxing an
            article), unless permission or license has been obtained or the
            copying falls under an exception such as “fair use,” as defined by
            the copyright laws, for purposes such as criticism, comment,
            background research, or teaching.
          </p>
        </section>

        <section>
          <h2>Infringement</h2>
          <p>
            All Company products contain proprietary content and/or software
            that are protected by copyright and other similar laws. Company
            claims copyright protection for all material that has been written
            or enhanced as a result of the efforts of Company’s editorial staff
            or indexers. Company does not claim copyright in United States
            government works, although Company does claim copyright for the
            compilation, selection, coordination, and arrangement of such
            material. Put simply, except for statutory exceptions such as “fair
            use,” no portion of a Company product may be reproduced in any way,
            unless expressly permitted. Infringement may result in criminal as
            well as civil liabilities for you and your employer.
          </p>

          <p>Copy, e-mail, or fax an entire (or part) issue of a publication</p>
          <p>
            You may never make copies of or transmit part or the entire contents
            of a Company publication, even if you have a membership with the
            Company. It does not matter whether the publication is in print or
            electronic form or if the copying or transmission is done on a
            one-time basis.
          </p>
        </section>

        <section>
          <h2>Notifications</h2>
          <p>
            The Company respects the intellectual property rights of others and
            expects its users to do the same. In accordance with the Digital
            Millennium Copyright Act of 1998, U.S. Copyright Act 17 U.S.C.
            512(c)(3) and regulations promulgated thereunder, each as amended,
            the text of which may be found on the U.S. Copyright Office website
            at{" "}
            <a href="http://www.copyright.gov/legislation/dmca.pdf">
              http://www.copyright.gov/legislation/dmca.pdf
            </a>
            , the Company will respond expeditiously to claims of copyright
            infringement committed using the Company service and/or the Company
            website if such claims are reported to the Company Designated
            Copyright Agent identified in the notice below.
          </p>

          <p>
            If you are a copyright owner, authorized to act on behalf of one, or
            authorized to act under any exclusive right under copyright, please
            report alleged copyright infringements taking place on or through
            Ultimed Pharmacy Mobile App by completing the following DMCA Notice of Alleged
            Infringement and delivering it to the Company Designated Copyright
            Agent. Upon receipt of Notice as described below, the Company will
            take whatever action, in its sole discretion, it deems appropriate,
            including removal of the challenged content from Ultimed Pharmacy Mobile App.
          </p>
        </section>
        <section>
          <h2>DMCA Notice of Alleged Infringement (“Notice”)</h2>

          <ol>
            <li>
              Identify the copyrighted work that you claim has been infringed,
              or - if multiple copyrighted works are covered by this Notice -
              you may provide a representative list of the copyrighted works
              that you claim have been infringed.
            </li>

            <li>
              Identify the material or link you claim is infringing (or the
              subject of infringing activity) and to which access is to be
              disabled, including at a minimum, if applicable, the URL of the
              link shown on Ultimed Pharmacy Mobile App or the exact location where such material
              may be found.
            </li>

            <li>
              Provide your company name (if applicable), mailing address,
              telephone number, and, if available, email address.
            </li>

            <li>
              Include both of the following statements in the body of the
              Notice:
              <ul>
                <li>
                  “I hereby state that I have a good faith belief that the
                  disputed use of the copyrighted material is not authorized by
                  the copyright owner, its agent, or the law (e.g., as a fair
                  use).”
                </li>
                <li>
                  “I hereby state that the information in this Notice is
                  accurate and, under penalty of perjury, that I am the owner,
                  or authorized to act on behalf of, the owner, of the copyright
                  or of an exclusive right under the copyright that is allegedly
                  infringed.”
                </li>
              </ul>
            </li>

            <li>
              Provide your full legal name and your electronic or physical
              signature.
            </li>
          </ol>

          <p>
            Deliver this Notice, with all items completed, to the Company
            Designated Copyright Agent:
          </p>
          <p>
            <strong>Zoe Nieves Velez</strong>
            <br />
            Email:{" "}
            <a href="mailto:info@medidohealth.com">
              info@medidohealth.com
            </a>
          </p>
        </section>

        <section>
          <h2>Governing Law</h2>
          <p>
            The Terms of Use will be governed by the laws of Puerto Rico, as
            applied to agreements entered into and to be performed entirely
            within Puerto Rico, without regard to its choice of law or conflicts
            of law principles that would require application of law of a
            different jurisdiction, and applicable foreign or federal law. The
            parties hereby consent to the exclusive jurisdiction and venue in
            the courts in Puerto Rico, or federal court for the District of
            Puerto Rico.
          </p>
        </section>

        <section>
          <h2>Headings</h2>
          <p>
            Headings are included for convenience only and shall not be
            considered in interpreting these Terms of Use. The Terms of Use do
            not limit any rights that the Company may have under trade secret,
            copyright, trademark, patent, or other laws.
          </p>
        </section>

        <section>
          <h2>Miscellaneous</h2>
          <p>
            Except as expressly set forth in these Terms of Use, these Terms of
            Use are a complete statement of the agreement between you and the
            Company, and set forth the entire liability of the Company and your
            exclusive remedy with respect to your access and use of Ultimed Pharmacy Mobile App. In
            the event of a conflict between these Terms of Use, the Privacy
            Policy, Intellectual Property Policy, or Cookie Statement, these
            Terms of Use shall prevail.
          </p>

          <p>
            The agents and employees of the Company are not authorized to make
            modifications to the Terms of Use, or to make any additional
            representations, commitments, or warranties binding on the Company.
            Any waiver of the Terms of Use herein by the Company must be in
            writing signed by an authorized officer of the Company and expressly
            referencing the applicable provisions of the Terms of Use.
          </p>

          <p>
            If any provision of the Terms of Use is invalid or unenforceable
            under applicable law, then it shall be changed and interpreted to
            accomplish the objectives of such provision to the greatest extent
            possible under applicable law, and the remaining provisions will
            continue in full force and effect.
          </p>

          <p>
            You agree that regardless of any statute or law to the contrary, any
            claim or cause of action arising out of or related to use of Ultimed Pharmacy Mobile App
            and/or the Terms of Use must be filed within one (1) year after such
            claim or cause of action arose or be forever barred.
          </p>
        </section>

        <section>
          <h2>Prescribed Drugs Delivery</h2>
          <p>
            The prescribed drugs ordered via Ultimed Pharmacy Mobile App byUltimed Pharmacy will be
            delivered by a carrier contracted byUltimed Pharmacy. You authorizeUltimed Pharmacy
            and the contractors to deliver the medicines to the designated
            place. This authorization includes, but is not limited to:
            collecting and accessing your personal protected health information
            as reasonably necessary to provide the requested service.
          </p>
        </section>

        <section>
          <h2>Acceptance</h2>
          <p>
            YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF USE, UNDERSTAND
            THE TERMS OF USE, AND WILL BE BOUND BY THESE TERMS OF USE AND
            CONDITIONS. YOU FURTHER ACKNOWLEDGE THAT THESE TERMS OF USE TOGETHER
            WITH THE PRIVACY POLICY, LEGAL STATEMENT, COOKIE STATEMENT, AND
            LICENSE AGREEMENT, IF ANY, REPRESENT THE COMPLETE AND EXCLUSIVE
            STATEMENT OF THE AGREEMENT BETWEEN US AND THAT IT SUPERSEDES ANY
            PROPOSAL OR PRIOR AGREEMENT ORAL OR WRITTEN, AND ANY OTHER
            COMMUNICATIONS BETWEEN US RELATING TO THE SUBJECT MATTER OF THIS
            AGREEMENT.
          </p>

          <p>
            We appreciate your taking the time to read these Terms of Use and we
            are very happy to have you using Ultimed Pharmacy Mobile App.
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfService;
