import React from "react";
import image from "../assets/term-image.png";

const TermsOfServiceEs = () => {
  return (
    <div className="terms-of-servicse">
      <div className="hero-section2">
        {/* <img src={image} alt="" className="main-image" /> */}
        <div className="main-content">
          <div className="overlay">
            <p className="text-logo">Términos de servicio</p>
          </div>
        </div>{" "}
      </div>
      <div className="terms">
        <section>
          <h2>Acuerdo</h2>
          <p>
            El presente acuerdo es entre usted o la entidad que usted representa
            (en adelante “Usuario” o “Usted”) y Physician Management Corp. de
            América, incluidas sus subsidiarias y/o entidades
            relacionadas/afiliadas (en adelante, la “Compañía”) que rigen su uso
            deUltimed Pharmacy Mobile App Portal y/o servicios (“Ultimed Pharmacy Mobile App”).
          </p>
          <p>
            Le damos la bienvenida como usuario deUltimed Pharmacy Mobile App. Antes de utilizar
            nuestros servicios, le pedimos que lea detenidamente y acepte los
            siguientes términos y condiciones (“Términos de Uso”).
          </p>
        </section>
        <section>
          <h2>Términos de Uso</h2>
          <p>
            Estos Términos de Uso rigen el uso deUltimed Pharmacy Mobile App. Su acceso aUltimed Pharmacy Mobile App
            de cualquier manera es evidencia de su aceptación de los Términos de
            Uso y la aplicación de dichos Términos de Uso. Si no está de acuerdo
            con estos Términos de Uso, no utiliceUltimed Pharmacy Mobile App.
          </p>
          <p>
            La Compañía se reserva el derecho, a su entera discreción, de
            cambiar, modificar o alterar estos Términos de Uso en cualquier
            momento al publicar los Términos de Uso modificados enUltimed Pharmacy Mobile App. Por
            favor, revise los Términos de Uso periódicamente. Su uso continuado
            deUltimed Pharmacy Mobile App, después de dicha publicación o notificación significa
            que acepta las modificaciones. El uso por su parte de cualquier
            contenido o servicio accesible a través deUltimed Pharmacy Mobile App puede estar
            sujeto a su aceptación de acuerdos separados con la Compañía o
            terceros.
          </p>
        </section>
        <section>
          <h2>Cuenta de Usuario</h2>
          <h3>Licencia y Acceso aUltimed Pharmacy Mobile App</h3>
          <p>
            La Compañía le otorga una licencia limitada para acceder y hacer uso
            personal deUltimed Pharmacy Mobile App. No puede descargar (aparte del almacenamiento
            en caché de la página) ni modificar ninguna parte de ella, excepto
            con el consentimiento expreso por escrito o por correo electrónico
            de la Compañía. La licencia de uso deUltimed Pharmacy Mobile App no incluye ningún uso
            comercial o de reventa, uso derivado o cualquier uso de minería de
            datos, robots o herramientas o procesos similares de recopilación y
            extracción de datos. Usted acepta no almacenar en ninguna forma,
            distribuir, transmitir, mostrar, reproducir, modificar, crear
            trabajos derivados, vender o explotar de otro modo cualquier
            contenido deUltimed Pharmacy Mobile App para ningún propósito comercial. Al utilizar
           Ultimed Pharmacy Mobile App, usted garantiza a la Compañía que no utilizaráUltimed Pharmacy Mobile App, ni
            ninguno de los contenidos obtenidos deUltimed Pharmacy Mobile App, para ningún
            propósito que sea ilegal o prohibido por estos Términos de Uso. La
            Compañía no otorga ninguna licencia u otra autorización a ningún
            usuario de sus marcas, derechos de autor y/o cualquier otra
            propiedad intelectual, colocándolos enUltimed Pharmacy Mobile App. Si viola cualquiera
            de estos Términos de Uso, su permiso para usarUltimed Pharmacy Mobile App termina
            automáticamente.
          </p>
          <p>
            No puede intentar obtener acceso no autorizado a ninguna parte o
            característica deUltimed Pharmacy Mobile App, o cualquier otro sistema o red conectada
            aUltimed Pharmacy Mobile App o a cualquiera de los servidores de la Compañía, o a
            cualquiera de los servicios ofrecidos en o a través deUltimed Pharmacy Mobile App,
            mediante piratería, “minería” de contraseñas o cualquier otro medio.
          </p>
          <p>
            En consideración a su uso deUltimed Pharmacy Mobile App, usted acepta: (a) proporcionar
            información verdadera, precisa, actual y completa sobre usted según
            lo solicitado por el formulario de registro, si corresponde, y (b)
            mantener y actualizar esta información para mantenerla verdadera,
            precisa, actual y completa. Si cualquier información proporcionada
            por usted es falsa, inexacta, no actual o incompleta, la Compañía
            tiene el derecho de cancelar su cuenta y rechazar cualquier uso
            actual o futuro deUltimed Pharmacy Mobile App. Usted acepta no sublicenciar, revender o
            transferir el uso o acceso aUltimed Pharmacy Mobile App, a menos que lo autorice
            expresamente por escrito un funcionario debidamente autorizado de la
            Compañía.
          </p>
        </section>
        <section>
          <h2>Dispositivos Móviles</h2>
          <p>
            El uso deUltimed Pharmacy Mobile App puede estar disponible a través de dispositivos
            móviles compatibles y puede requerir un plan de datos. Usted acepta
            que es el único responsable de dichos requisitos, incluidas las
            tarifas aplicables, así como los términos de su acuerdo con su
            proveedor de servicios de dispositivos móviles.
          </p>
          <p>
            Usted entiende y acepta que no todas las características y
            funcionalidades pueden estar disponibles en dispositivos móviles
            compatibles.
          </p>
          <p>
            Además, comprende y acepta que el acceso aUltimed Pharmacy Mobile App está sujeto a la
            disponibilidad de la red de su proveedor de servicios de
            dispositivos móviles y la Compañía no es responsable de la
            disponibilidad de los servicios de su proveedor, incluida cualquier
            pérdida, daño, error o falta de transmisión.
          </p>
        </section>
        <section>
          <h2>Mensajes de Texto</h2>
          <p>
            Si ha optado por recibir mensajes de texto de nuestra parte, se
            aplican los siguientes Términos de Uso y condiciones:
          </p>
          <ul>
            <li>
              Puede responder con un mensaje de texto “STOP” en cualquier
              momento para dejar de recibir mensajes de nosotros.
            </li>
            <li>
              Puede enviar un correo electrónico a{" "}
              <a href="mailto:info@medidohealth.com">info@medidohealth.com</a> en
              cualquier momento para recibir información sobre cómo acceder al
              servicio de atención al cliente.
            </li>
            <li>
              Las tarifas de mensajes y datos pueden aplicarse a cualquier
              mensaje que envíe o reciba de nosotros.
            </li>
            <li>
              Puede esperar recibir hasta dos mensajes de nosotros cuando se
              inscribe en un programa: uno después de haber optado por el
              programa y otro confirmando su inscripción.
            </li>
          </ul>
        </section>
        <section>
          <h2>Comunicaciones Electrónicas</h2>
          <p>
            Cuando visitaUltimed Pharmacy Mobile App o nos envía correos electrónicos, se está
            comunicando con nosotros electrónicamente. Usted acepta recibir
            comunicaciones de nosotros electrónicamente. Nos comunicaremos con
            usted por correo electrónico o mediante la publicación de avisos en
           Ultimed Pharmacy Mobile App. Usted acepta que todos los acuerdos, avisos, divulgaciones
            y otras comunicaciones que le proporcionamos electrónicamente
            satisfacen cualquier requisito legal de que dicha comunicación sea
            por escrito.
          </p>
          <p>
            Usted es responsable de proporcionar, a su cargo, cualquier acceso a
            Internet y cualquier equipo requerido.
          </p>
        </section>
        <section>
          <h2>Política de Uso Aceptable</h2>
          <p>
            Esta política establece requisitos para el uso deUltimed Pharmacy Mobile App para
            garantizar la provisión de información y perfiles de medicamentos,
            historial de recetas, Registro Kardex e informes de pacientes.
          </p>
          <p>
            Al utilizarUltimed Pharmacy Mobile App, usted acepta que no realizará ninguna de las
            siguientes acciones:
          </p>
          <ol>
            <li>
              Restringir o inhibir a cualquier otro usuario de usar y disfrutar
              deUltimed Pharmacy Mobile App; o
            </li>
            <li>
              Cargar cualquier información política, ilegal, fraudulenta,
              calumniosa, difamatoria, obscena, pornográfica, profana,
              amenazante, abusiva, odiosa, ofensiva u objetable de cualquier
              tipo, incluidas, entre otras, las transmisiones que constituyan o
              alienten una conducta que constituya delito, ocasione
              responsabilidad civil o viole cualquier ley local, estatal,
              nacional o extranjera, incluidas, entre otras, las leyes y
              regulaciones de control de exportaciones de los Estados Unidos; o
            </li>
            <li>
              Cargar anuncios, solicitudes, cartas en cadena, esquemas
              piramidales, oportunidades o esquemas de inversión u otra
              comunicación comercial no solicitada (salvo que la Compañía lo
              permita expresamente) o participar en “spam” o inundaciones; o
            </li>
            <li>
              Cargar cualquier información o software que contenga un virus,
              troyano, gusano u otro componente dañino; o
            </li>
            <li>
              Publicar, publicar, transmitir, reproducir, distribuir o explotar
              de cualquier manera cualquier información, software u otro
              material obtenido a través deUltimed Pharmacy Mobile App con fines comerciales (que
              no sean los expresamente permitidos por el proveedor de dicha
              información, software u otro material); o
            </li>
            <li>
              Cargar, publicar, publicar, transmitir, reproducir o distribuir de
              cualquier manera, información, software u otro material obtenido a
              través deUltimed Pharmacy Mobile App que esté protegido por derechos de autor u otros
              derechos de propiedad, o trabajos derivados con respecto a los
              mismos, sin obtener el permiso del propietario de los derechos de
              autor o del titular de los derechos.
            </li>
          </ol>
          <p>
            La Compañía no tiene la obligación de monitorearUltimed Pharmacy Mobile App. Sin
            embargo, usted reconoce y acepta que la Compañía tiene el derecho de
            monitorearUltimed Pharmacy Mobile App electrónicamente de vez en cuando y divulgar
            cualquier información según sea necesario o apropiado para
            satisfacer cualquier ley, regulación u otra solicitud gubernamental,
            operación adecuada o para protegerse a sí misma o a sus
            suscriptores.
          </p>
          <p>
            La Compañía no monitoreará ni divulgará intencionalmente ningún
            mensaje de correo electrónico privado a menos que sea requerido por
            ley. La Compañía se reserva el derecho de negarse a publicar o
            eliminar cualquier información o registro, en su totalidad o en
            parte, que, a su entera discreción, sea inaceptable, indeseable,
            inapropiado o que viole estos Términos de Uso.
          </p>
        </section>
        <section>
          <h2>Retroalimentación</h2>
          <p>
            La Compañía puede proporcionarle un mecanismo para proporcionar
            comentarios, sugerencias e ideas, si lo desea, sobreUltimed Pharmacy Mobile App. Usted
            acepta que la Compañía puede, a su entera discreción, utilizar el
            feedback que proporcione de cualquier manera, incluso en futuras
            modificaciones deUltimed Pharmacy Mobile App y/o materiales publicitarios y
            promocionales relacionados con el mismo. Por la presente, usted
            otorga a la Compañía una licencia perpetua, mundial, totalmente
            transferible, irrevocable y libre de regalías para usar, reproducir,
            modificar, crear trabajos derivados, distribuir y mostrar el
            feedback de cualquier manera y para cualquier propósito.
          </p>
        </section>
        <section>
          <h2>Servicios de Terceros</h2>
          <p>
            En relación con su uso deUltimed Pharmacy Mobile App, es posible que se le informe de
            los servicios, productos, ofertas y promociones proporcionados por
            terceros, y no por la Compañía. Si decide utilizar los servicios de
            terceros, usted es responsable de revisar y comprender los términos
            y condiciones que rigen cualquier servicio de terceros. Usted acepta
            que el tercero, y no la Compañía, es responsable del desempeño de
            los servicios de terceros.
          </p>
          <p>
            Entre los terceros autorizados para proporcionar servicios
            relacionados con su uso deUltimed Pharmacy Mobile App se encuentran:
          </p>
          <ul>
            <li>Physician Management Corporation of America;</li>
            <li>Specialty Solutions Inc;</li>
            <li>Medido Health LLC;</li>
          </ul>
          <p>Nos reservamos el derecho de incluir a otros en el futuro.</p>
        </section>
        <section>
          <h2>Sitios web de Terceros</h2>
          <p>
           Ultimed Pharmacy Mobile App puede contener o hacer referencia a enlaces a sitios web
            operados por un tercero ("Sitios web de terceros"). Estos enlaces se
            proporcionan solo para su conveniencia. Dichos sitios web de
            terceros no están bajo el control de la Compañía. La Compañía no es
            responsable del contenido o enlace contenido en cualquier sitio web
            de terceros. La Compañía no revisa, aprueba, monitorea, respalda,
            garantiza ni hace ninguna representación con respecto a los sitios
            web de terceros, y la inclusión de cualquier enlace enUltimed Pharmacy Mobile App no es
            ni implica una afiliación, patrocinio, respaldo, aprobación,
            investigación, verificación o monitoreo por parte de la Compañía de
            cualquier información contenida en cualquier sitio web de terceros.
            En ningún caso la Compañía será responsable de la información
            contenida en dichos sitios web de terceros o de su uso o incapacidad
            de usar dichos sitios web. El acceso a cualquier sitio web de
            terceros es bajo el propio riesgo del Usuario, y el Usuario reconoce
            y entiende que los sitios web de terceros vinculados pueden contener
            términos de uso y políticas de privacidad que son diferentes de las
            de la Compañía. La Compañía no es responsable de tales disposiciones
            y renuncia expresamente a cualquier responsabilidad por ellas. Debe
            revisar los términos y políticas aplicables, incluidas las prácticas
            de privacidad y recopilación de datos, de cualquier sitio web de
            terceros.
          </p>
        </section>
        <section>
          <h2>Términos de Uso Adicionales</h2>
          <h3>Descargo de Responsabilidad de Garantías</h3>
          <p>
            Su uso deUltimed Pharmacy Mobile App, incluidos los “applets”, el “software” y el
            contenido contenido en él, es bajo su propio riesgo. EL SERVICIO SE
            PROPORCIONA "TAL CUAL" Y, EN LA MEDIDA MÁXIMA PERMITIDA POR LA LEY
            APLICABLE, LA COMPAÑÍA, SUS AFILIADOS, LICENCIANTES, PROVEEDORES DE
            CONTENIDO O SERVICIOS DE TERCEROS, DISTRIBUIDORES, DISTRIBUIDORES Y
            PROVEEDORES (COLECTIVAMENTE "PROVEEDORES") RENUNCIAN A TODAS LAS
            GARANTÍAS Y GARANTÍAS, YA SEAN EXPRESAS, IMPLÍCITAS O ESTATUTARIAS
            CON RESPECTO AL USO DEUltimed Pharmacy Mobile App Y MATERIALES RELACIONADOS, INCLUIDA
            CUALQUIER GARANTÍA PARA UN PROPÓSITO PARTICULAR, TÍTULO,
            COMERCIABILIDAD Y NO VIOLACIÓN. LA COMPAÑÍA NO GARANTIZA NI
            GARANTIZA LA EXACTITUD, CONFIABILIDAD, INTEGRIDAD, UTILIDAD, NO
            VIOLACIÓN DE LOS DERECHOS DE PROPIEDAD INTELECTUAL O CALIDAD DE
            CUALQUIER CONTENIDO ENUltimed Pharmacy Mobile App, INDEPENDIENTEMENTE DE QUIÉN ORIGINE
            ESE CONTENIDO. LA COMPAÑÍA NO GARANTIZA QUEUltimed Pharmacy Mobile App SEA SEGURO,
            LIBRE DE ERRORES, VIRUS, INTERRUPCIONES, ERRORES, ROBO O DESTRUCCIÓN
            O QUEUltimed Pharmacy Mobile App CUMPLA CON SUS REQUISITOS. USTED ENTIENDE Y ACEPTA
            EXPRESAMENTE QUE ASUME TODOS LOS RIESGOS ASOCIADOS CON EL USO O LA
            CONFIANZA EN ESE CONTENIDO. LA COMPAÑÍA NO ES RESPONSABLE DE NINGUNA
            MANERA POR CUALQUIER CONTENIDO PUBLICADO O VINCULADO AUltimed Pharmacy Mobile App,
            INCLUIDOS, ENTRE OTROS, ERRORES U OMISIONES EN EL CONTENIDO, O POR
            CUALQUIER PÉRDIDA O DAÑO DE CUALQUIER TIPO INCURRIDO COMO RESULTADO
            DEL USO O LA CONFIANZA EN CUALQUIER CONTENIDO.
          </p>

          <h3>Limitación de Responsabilidad</h3>
          <p>
            LA RESPONSABILIDAD ACUMULADA TOTAL DE LA COMPAÑÍA, SUS AFILIADOS Y
            SUS PROVEEDORES, POR CUALQUIER DAÑO DIRECTO, INDIRECTO, INCIDENTAL,
            ESPECIAL, CONSECUENCIAL O EJEMPLAR, INCLUYENDO, SIN LIMITACIÓN,
            DAÑOS POR PÉRDIDA DE GANANCIAS O INGRESOS, INTERRUPCIÓN DE NEGOCIO,
            PÉRDIDA DE INFORMACIÓN COMERCIAL O CUALQUIER OTRO DAÑO O PÉRDIDA QUE
            SURJA DE O ESTÉ RELACIONADA CON EL USO DEUltimed Pharmacy Mobile App, YA SEA BASADA EN
            GARANTÍA, CONTRATO, AGRAVIO (INCLUYENDO NEGLIGENCIA),
            RESPONSABILIDAD ESTRICTA O CUALQUIER OTRA TEORÍA LEGAL, Y SI LA
            COMPAÑÍA HA SIDO ADVERTIDA O NO DE LA POSIBILIDAD DE DICHOS DAÑOS,
            NO SUPERARÁ EN NINGÚN CASO LA CANTIDAD QUE USTED HA PAGADO A LA
            COMPAÑÍA, SI LA HUBIERA, EN RELACIÓN CON EL USO DEUltimed Pharmacy Mobile App.
          </p>
        </section>
        <section>
          <h2>Declaración de Cookies</h2>
          <p>
            UltimedPharmacy utiliza cookies y otras tecnologías relacionadas
            (para conveniencia, todas las tecnologías se denominan "cookies").
            Las cookies también son colocadas por terceros que hemos contratado.
            En el documento a continuación le informamos sobre el uso de cookies
            en UltimedPharmacy.
          </p>
          <p>
            Una cookie es un pequeño archivo simple que se envía junto con las
            páginas de UltimedPharmacy y se almacena por su navegador en el
            disco duro de su computadora u otro dispositivo. La información
            almacenada en ella puede ser devuelta a nuestros servidores o a los
            servidores de los terceros relevantes durante una visita posterior.
          </p>
          <p>
            Muchos navegadores pueden notificarle cuando recibe una cookie,
            dándole la opción de aceptarla o no.
          </p>
          <p>
            Al no aceptar cookies, es posible que no se le permita acceder a
            cierta información y UltimedPharmacy puede no mostrarse
            correctamente.
          </p>
          <p>
            Cuando visite UltimedPharmacy, se establecerán las siguientes
            categorías de cookies en su navegador:
          </p>
          <ul>
            <li>
              <strong>Cookies estrictamente necesarias:</strong> Estas cookies
              son esenciales para permitirle moverse por UltimedPharmacy y
              utilizar sus funciones. Sin estas cookies, los servicios que ha
              solicitado no pueden ser proporcionados.
            </li>
            <li>
              <strong>Cookies de rendimiento:</strong> Estas cookies recopilan
              información para que podamos analizar cómo utilizan los visitantes
              UltimedPharmacy. Estas cookies no recopilan información que lo
              identifique. Toda la información que recopilan estas cookies es
              anónima y solo se utiliza para mejorar el funcionamiento de
              UltimedPharmacy.
            </li>
            <li>
              <strong>Cookies de funcionalidad:</strong> Estas cookies permiten
              a UltimedPharmacy recordar las elecciones que hace (por ejemplo,
              su nombre de usuario o la región en la que se encuentra) y
              proporcionar características mejoradas y más personales. Podemos
              usar la información recopilada de las cookies de funcionalidad
              para identificar el comportamiento del usuario y para ofrecer
              contenido basado en su perfil de usuario. Estas cookies no pueden
              rastrear su actividad de navegación fuera de UltimedPharmacy. No
              recopilan ninguna información sobre usted que pueda ser utilizada
              para publicidad o para registrar dónde ha estado en Internet fuera
              de UltimedPharmacy.
            </li>
            <li>
              <strong>Cookies de análisis:</strong> Para mantener
              UltimedPharmacy relevante, fácil de usar y actualizada, utilizamos
              servicios de análisis para ayudarnos a entender cómo las personas
              usan UltimedPharmacy.
            </li>
          </ul>
          <p>
            Un script es un fragmento de código de programa que se utiliza para
            hacer que UltimedPharmacy funcione correctamente e interactivamente.
            Este código se ejecuta en nuestro servidor o en su dispositivo.
          </p>
          <p>
            Un web beacon (o una etiqueta de píxel) es un pequeño texto o imagen
            invisible en UltimedPharmacy que se utiliza para monitorear el
            tráfico en UltimedPharmacy. Para hacer esto, se almacena varios
            datos sobre usted utilizando web beacons.
          </p>
          <p>
            Cuando visite UltimedPharmacy por primera vez, acepta que utilicemos
            todas las cookies, scripts, web beacons y complementos según se
            describe en esta declaración de cookies. Puede deshabilitar el uso
            de cookies a través de su navegador, pero tenga en cuenta que
            UltimedPharmacy puede no funcionar correctamente.
          </p>
          <p>
            Usted tiene los siguientes derechos con respecto a sus datos
            personales:
          </p>
          <ul>
            <li>
              Tiene el derecho de saber por qué se necesitan sus datos
              personales, qué sucederá con ellos y cuánto tiempo se conservarán.
            </li>
            <li>
              Tiene el derecho de acceder a sus datos personales que conocemos.
            </li>
            <li>
              Tiene el derecho de complementar, corregir, eliminar o bloquear
              sus datos personales siempre que lo desee, siempre y cuando no
              esté prohibido por las leyes estatales y federales aplicables.
            </li>
            <li>
              Tiene el derecho de revocar el consentimiento y de que se eliminen
              sus datos personales, siempre que no esté prohibido por las leyes
              estatales y federales aplicables.
            </li>
          </ul>
          <p>
            Para ejercer estos derechos o si tiene una queja sobre cómo
            manejamos sus datos, comuníquese con nosotros.
          </p>
          <p>
            Puede usar su navegador de Internet para eliminar cookies
            automáticamente o manualmente. También puede especificar que no se
            coloquen ciertas cookies. Otra opción es cambiar la configuración de
            su navegador para recibir un mensaje cada vez que se coloque una
            cookie. Para obtener más información sobre estas opciones, consulte
            las instrucciones en la sección de ayuda de su navegador.
          </p>
          <p>
            Tenga en cuenta que UltimedPharmacy puede no funcionar correctamente
            si todas las cookies están deshabilitadas. Si elimina las cookies en
            su navegador, se volverán a colocar después de su consentimiento
            cuando visite UltimedPharmacy nuevamente.
          </p>
          <p>
            Para preguntas y/o comentarios sobre nuestra política de cookies y
            esta declaración, comuníquese con nosotros por correo electrónico a:{" "}
            <a href="mailto:info@medidohealth.com">
              info@medidohealth.com
            </a>
          </p>
        </section>
        <section>
          <h2>Derechos de Propiedad Intelectual</h2>
          <p>
            Las marcas comerciales de la Compañía son activos valiosos de la
            Compañía y están legalmente protegidas en Puerto Rico y en los
            Estados Unidos. Estos Términos de Uso proporcionan pautas generales
            sobre el uso adecuado, permitido y prohibido de las marcas
            comerciales de la Compañía. Los Términos de Uso no constituyen una
            licencia, opinión legal y/o asesoramiento legal, y están sujetos a
            cambios sin previo aviso. Las marcas comerciales no propiedad de la
            Compañía son propiedad de sus respectivos titulares.
          </p>

          <p>
            La Compañía se reserva todos los derechos con respecto a sus marcas
            comerciales, nombres de servicio, logotipos, nombres comerciales,
            diseño comercial y cualquier otra propiedad intelectual ("Marcas
            Comerciales"). La Compañía no acepta ni revisa rutinariamente
            solicitudes específicas para obtener orientación sobre el uso de sus
            Marcas Comerciales. Cualquier uso no autorizado o cualquier uso de
            una marca comercial que sea confusamente similar o que probablemente
            cause confusión, constituiría una infracción de los derechos de las
            Marcas Comerciales. Este documento NO constituye ninguna forma de
            licencia para usar ninguna de las Marcas Comerciales. Ningún
            empleado de la Compañía está autorizado para proporcionar
            orientación sobre el uso de las Marcas Comerciales, ni para dar
            permiso para usar ninguna Marca Comercial, excepto mediante un
            acuerdo de licencia formal por escrito con la Compañía. Cualquier
            promesa, acuerdo, orientación u otra representación por parte de
            cualquier empleado de la Compañía (que no sea mediante una licencia
            formal por escrito como se describe aquí) es inválida y no puede ser
            confiada. Si tiene preguntas sobre las leyes que rodean el uso de
            las Marcas Comerciales, o sobre las marcas de otras partes, debe
            consultar a un abogado calificado.
          </p>

          <p>
            Esta lista de algunas marcas comerciales no es en modo alguno una
            lista exhaustiva y no limita de ninguna manera el número de marcas
            comerciales en las que la Compañía reclama propiedad o el alcance de
            las reclamaciones de la Compañía.
          </p>

          <p>
            Esta lista simplemente proporciona una guía sobre la colocación y la
            ortografía adecuada de las Marcas Comerciales:
          </p>
          <ul>
            <li>UltimedPharmacy MobileApp</li>
            <li>UltimedPharmacy</li>
            <li>Medpak</li>
            <li>Medcycle</li>
            <li>Meducate</li>
            <li>Medvax</li>
            <li>Medmatch</li>
            <li>ME</li>
          </ul>

          <p>
            Puede hacer un uso puramente referencial de las Marcas Comerciales
            como se describe en esta sección, pero debe consultar a su propio
            asesor legal sobre lo que constituye o no un uso justo (descriptivo
            o nominativo) de las marcas comerciales de otra empresa, y lo que
            puede constituir un uso no autorizado o infractor. Al hacer
            referencia a las Marcas Comerciales, el uso del logotipo corporativo
            de la Compañía, logotipos de productos o diseños de cajas NO está
            permitido sin una licencia escrita de la Compañía. Los usos
            referenciales de las Marcas Comerciales deben hacerse únicamente en
            texto plano.
          </p>

          <p>
            El uso en texto plano de las Marcas Comerciales en contextos
            comerciales solo puede hacerse para fines como describir la
            capacidad de capacitar a otros en el uso de productos de la Compañía
            (en el caso de tales servicios) o declaraciones verdaderas y
            fácticas sobre la naturaleza de cualquier relación con la Compañía,
            donde exista una. Cualquier uso de las Marcas Comerciales o
            declaraciones que impliquen respaldo, afiliación, certificación u
            otra relación - donde no exista - está estrictamente prohibido.
            Puede haber situaciones en las que sea apropiado que usted indique
            en sus materiales de marketing, o en el embalaje de sus propias
            ofertas, que su producto o servicio es "para usar con" o está
            "diseñado para funcionar con" o "compatible con" un producto de la
            Compañía. Consulte a un abogado calificado si tiene preguntas sobre
            el uso nominativo justo de las Marcas Comerciales.
          </p>

          <p>
            El uso de las Marcas Comerciales dentro de los nombres de productos,
            nombres de servicios, nombres comerciales/comerciales, DBA's,
            nombres de dominio u otros indicios de su empresa está estrictamente
            prohibido.
          </p>

          <p>
            El uso referencial de las Marcas Comerciales debe incluir el
            símbolo(s) de marca registrada adecuado(s). Cuando sea posible, la
            Compañía solicita que aquellos que hagan referencia a los productos
            de la Compañía con sus marcas comerciales asociadas también
            proporcionen una breve declaración de atribución de propiedad en
            algún lugar dentro de esos materiales. Típicamente, la declaración
            de atribución se muestra al final del material, en el pie de página
            del documento o sitio y debe leer: "Marca comercial y el logotipo de
            Marca Comercial son marcas comerciales o marcas comerciales
            registradas de la Compañía. Cualquier uso sin el consentimiento
            expreso por escrito de la Compañía está prohibido."
          </p>

          <p>
            Las marcas comerciales son adjetivos y generalmente deben usarse en
            conexión con los sustantivos genéricos que modifican. Dado que las
            marcas comerciales son adjetivos, no deben usarse como sustantivos o
            verbos.
          </p>
        </section>
        <section>
          <h2>Patentes</h2>
          <p>
            Las características y servicios dentro de los productos de la
            Compañía pueden ser objeto de patentes pendientes o emitidas.
          </p>
        </section>
        <section>
          <h2>Capturas de Pantalla</h2>
          <p>
            Las capturas de pantalla son las pantallas individuales contenidas
            dentro de las aplicaciones y servicios en línea de la Compañía.
            Puede hacer un uso justo de un número limitado de capturas de
            pantalla para fines puramente ilustrativos siempre que cumpla con
            las siguientes pautas:
          </p>
          <ol>
            <li>
              Su uso no debe declarar ni implicar directa o indirectamente
              patrocinio, afiliación o respaldo de su producto o servicio por
              parte de la Compañía.
            </li>
            <li>
              No debe alterar la captura de pantalla de ninguna manera, excepto
              para redimensionarla en proporción directa al original. Las
              capturas de pantalla deben reproducirse en su totalidad.
            </li>
            <li>
              Puede añadir comentarios u otros análisis de texto siempre que
              esté claramente atribuible a usted y no a la Compañía.
            </li>
            <li>
              No puede utilizar capturas de pantalla de los productos de la
              Compañía u otros productos que estén sujetos a leyes y/o
              obligaciones de no divulgación, y/o que aún no hayan sido lanzados
              comercialmente por la Compañía.
            </li>
            <li>
              No puede usar capturas de pantalla que contengan contenido de
              terceros a menos que obtenga permiso por separado de dicho tercero
              para mostrar su contenido.
            </li>
            <li>
              Debe incluir la siguiente declaración de atribución de derechos de
              autor en todos los materiales que contengan capturas de pantalla
              de la Compañía: "Reimpreso con permiso © Compañía. Todos los
              derechos reservados." (Ejemplo: "Reimpreso con permiso ©
              UltimedPharmacy. Todos los derechos reservados.")
            </li>
            <li>
              Si sus materiales incluyen referencias a un producto de la
              Compañía, el nombre completo del producto con el símbolo de marca
              correspondiente debe mostrarse en texto plano en la primera y/o
              mención más destacada en dichos materiales. Al referirse a
              cualquier marca comercial de la Compañía, asegúrese de que su uso
              cumpla con las restricciones de marcas comerciales.
            </li>
            <li>
              Su uso de las capturas de pantalla de la Compañía no debe ser
              incorporado en material político, obsceno o pornográfico, y no
              debe, a criterio exclusivo de la Compañía, ser difamatorio,
              calumnioso o injurioso hacia la Compañía, cualquiera de sus
              productos, o cualquier otra persona o entidad.
            </li>
            <li>
              Sus materiales no deben estar compuestos mayormente o
              exclusivamente por capturas de pantalla o propiedad intelectual de
              la Compañía. Si las capturas de pantalla o propiedad intelectual
              de la Compañía representan la mayoría del material que desea
              reproducir y/o distribuir, debe escribir primero a la Compañía
              solicitando permiso antes de publicar o distribuir dicho
              contenido. Las solicitudes deben ser por escrito y enviadas a la
              Compañía por correo electrónico a{" "}
              <a href="mailto:info@medidohealth.com">info@medidohealth.com</a>.
            </li>
          </ol>
          <p>
            Si su uso cumple con todos los criterios anteriores, no se requiere
            permiso por escrito. Cualquier otro uso de las capturas de pantalla
            de la Compañía requiere permiso previo por escrito de la Compañía,
            incluyendo pero no limitado a cualquier uso propuesto de capturas de
            pantalla en o para publicidad, video, televisión, películas o
            comerciales. Si se requiere permiso adicional por escrito, envíe una
            carta detallando su solicitud a la Compañía a través de la sección
            "Contáctenos". Por favor, permita un mínimo de dos (2) semanas para
            que su solicitud sea procesada.
          </p>

          <p>
            La Compañía no objeta el uso de pequeñas porciones de guías de
            usuario redactadas por la Compañía para fines puramente
            educativos/instruccionales, siempre que cumpla con todas las
            siguientes pautas:
          </p>
          <ol>
            <li>
              Su uso no debe implicar directa o indirectamente patrocinio,
              afiliación o respaldo de su producto o servicio por parte de la
              Compañía.
            </li>
            <li>
              No puede vender por separado con fines de lucro ninguna parte de
              una guía de usuario redactada por la Compañía.
            </li>
            <li>
              No puede alterar una guía de usuario redactada por la Compañía o
              cualquier material contenido en ella de ninguna manera.
            </li>
            <li>
              Su uso no debe ser incorporado en material político, obsceno o
              pornográfico, y no debe ser difamatorio, calumnioso o injurioso
              hacia la Compañía, cualquiera de sus productos, o cualquier otra
              persona o entidad.
            </li>
          </ol>
          <p>
            Si su uso cumple con los criterios anteriores, no se requiere
            permiso por escrito. Cualquier otro uso de las guías de usuario
            redactadas por la Compañía requiere permiso previo por escrito de la
            Compañía. Si se requiere permiso adicional por escrito, envíe una
            carta detallando su solicitud a la Compañía a través de la sección
            "Contáctenos". Por favor, permita un mínimo de dos (2) semanas para
            que su solicitud sea procesada.
          </p>
        </section>
        <section>
          <h2>Derechos de Autor</h2>
          <p>
            Las leyes de derechos de autor otorgan a la Compañía (como
            propietaria de los derechos de autor) el derecho exclusivo de
            reproducir, preparar trabajos derivados, distribuir, ejecutar y
            mostrar públicamente un trabajo con derechos de autor. Las leyes
            están destinadas a proteger la propiedad intelectual del propietario
            en el trabajo y a proteger las obras impresas y electrónicas, como
            publicaciones, libros, aplicaciones basadas en la web, videos y
            software. Las obras están protegidas incluso si no están registradas
            en la Oficina de Derechos de Autor de los Estados Unidos, o no
            llevan el símbolo de derechos de autor "©". En general, no se
            permite la copia de materiales protegidos por derechos de autor, ya
            sea por fotocopia o por medios electrónicos (por ejemplo, enviar un
            artículo por correo electrónico o fax), a menos que se haya obtenido
            permiso o licencia o que la copia caiga bajo una excepción como "uso
            justo", según lo definido por las leyes de derechos de autor, para
            fines tales como crítica, comentario, investigación de antecedentes
            o enseñanza.
          </p>
        </section>
        <section>
          <h2>Violación</h2>
          <p>
            Todos los productos de la Compañía contienen contenido propietario
            y/o software que están protegidos por derechos de autor y otras
            leyes similares. La Compañía reclama la protección de derechos de
            autor para todo el material que ha sido escrito o mejorado como
            resultado de los esfuerzos del personal editorial o indexadores de
            la Compañía. La Compañía no reclama derechos de autor en obras del
            gobierno de los Estados Unidos, aunque la Compañía sí reclama
            derechos de autor para la compilación, selección, coordinación y
            disposición de dicho material. En pocas palabras, a excepción de
            excepciones legales como el "uso justo", ninguna parte de un
            producto de la Compañía puede reproducirse de ninguna manera, a
            menos que se permita expresamente. La violación puede resultar en
            responsabilidades penales y civiles para usted y su empleador.
          </p>

          <p>
            No puede copiar, enviar por correo electrónico o fax un número
            completo (o parcial) de una publicación.
          </p>
          <p>
            Nunca puede hacer copias o transmitir parte o todo el contenido de
            una publicación de la Compañía, incluso si tiene una membresía con
            la Compañía. No importa si la publicación es impresa o electrónica o
            si la copia o transmisión se realiza por una sola vez.
          </p>
        </section>
        <section>
          <h2>Notificaciones</h2>
          <p>
            La Compañía respeta los derechos de propiedad intelectual de otros y
            espera que sus usuarios hagan lo mismo. De acuerdo con la Ley de
            Derechos de Autor del Milenio Digital de 1998, Ley de Derechos de
            Autor de los EE. UU. 17 U.S.C. 512(c)(3) y las regulaciones
            promulgadas en virtud de la misma, cada una según enmendada, cuyo
            texto se puede encontrar en el sitio web de la Oficina de Derechos
            de Autor de los EE. UU. en{" "}
            <a href="http://www.copyright.gov/legislation/dmca.pdf">
              http://www.copyright.gov/legislation/dmca.pdf
            </a>
            , la Compañía responderá rápidamente a las reclamaciones de
            violación de derechos de autor cometidas utilizando el servicio de
            la Compañía y/o el sitio web de la Compañía si tales reclamaciones
            se informan al Agente de Derechos de Autor Designado por la Compañía
            identificado en el aviso a continuación.
          </p>

          <p>
            Si usted es propietario de derechos de autor, está autorizado para
            actuar en nombre de uno, o está autorizado para actuar bajo
            cualquier derecho exclusivo bajo derechos de autor, informe las
            presuntas violaciones de derechos de autor que tengan lugar en o a
            través deUltimed Pharmacy Mobile App completando el siguiente Aviso de presunta
            violación de la DMCA y entregándolo al Agente de derechos de autor
            designado por la Compañía. Al recibir el Aviso como se describe a
            continuación, la Compañía tomará las medidas que, a su entera
            discreción, considere apropiadas, incluida la eliminación del
            contenido cuestionado deUltimed Pharmacy Mobile App.
          </p>
        </section>

        <section>
          <h2>Aviso de Presunta Violación de la DMCA (“Aviso”)</h2>

          <ol>
            <li>
              Identifique el trabajo protegido por derechos de autor que usted
              afirma que ha sido infringido, o - si varios trabajos con derechos
              de autor están cubiertos por este Aviso - puede proporcionar una
              lista representativa de los trabajos con derechos de autor que
              usted afirma que han sido infringidos.
            </li>

            <li>
              Identifique el material o enlace que usted afirma que está
              infringiendo (o el objeto de una actividad infractora) y al que se
              debe deshabilitar el acceso, incluyendo como mínimo, si
              corresponde, la URL del enlace que se muestra en UltimedPharmacy o
              la ubicación exacta donde se puede encontrar dicho material.
            </li>

            <li>
              Proporcione el nombre de su empresa (si corresponde), la dirección
              postal, el número de teléfono y, si está disponible, la dirección
              de correo electrónico.
            </li>

            <li>
              Incluya las dos siguientes declaraciones en el cuerpo del Aviso:
              <ul>
                <li>
                  “Por la presente declaro que creo de buena fe que el uso
                  disputado del material protegido por derechos de autor no está
                  autorizado por el propietario de los derechos de autor, su
                  agente o la ley (por ejemplo, como un uso justo).”
                </li>
                <li>
                  “Por la presente declaro que la información en este Aviso es
                  precisa y, bajo pena de perjurio, que soy el propietario, o
                  estoy autorizado para actuar en nombre del propietario, de los
                  derechos de autor o de un derecho exclusivo bajo los derechos
                  de autor que presuntamente se ha infringido.”
                </li>
              </ul>
            </li>

            <li>
              Proporcione su nombre legal completo y su firma electrónica o
              física.
            </li>
          </ol>

          <p>
            Entregue este Aviso, con todos los artículos completados, al Agente
            de Derechos de Autor Designado por la Compañía:
          </p>
          <p>
            <strong>Zoe Nieves Velez</strong>
            <br />
            Correo electrónico:{" "}
            <a href="mailto:info@medidohealth.com">
              info@medidohealth.com
            </a>
          </p>
        </section>

        <section>
          <h2>Legislación Aplicable</h2>
          <p>
            Los Términos de Uso se regirán por las leyes de Puerto Rico, tal
            como se aplican a los acuerdos celebrados y que se realizarán en su
            totalidad dentro de Puerto Rico, sin tener en cuenta su elección de
            ley o conflictos de principios legales que requerirían la aplicación
            de la ley de una jurisdicción diferente, y la ley extranjera o
            federal aplicable. Por la presente, las partes aceptan la
            jurisdicción exclusiva y el lugar en los tribunales de Puerto Rico o
            en el tribunal federal del Distrito de Puerto Rico.
          </p>
        </section>

        <section>
          <h2>Encabezados</h2>
          <p>
            Los encabezados se incluyen solo por conveniencia y no se
            considerarán al interpretar estos Términos de Uso. Los Términos de
            Uso no limitan ningún derecho que la Compañía pueda tener bajo
            secreto comercial, derechos de autor, marca registrada, patente u
            otras leyes.
          </p>
        </section>

        <section>
          <h2>Misceláneo</h2>
          <p>
            Salvo que se establezca expresamente en estos Términos de Uso, estos
            Términos de Uso son una declaración completa del acuerdo entre usted
            y la Compañía, y establecen toda la responsabilidad de la Compañía y
            su recurso exclusivo con respecto a su acceso y uso de
            UltimedPharmacy. En caso de conflicto entre estos Términos de Uso,
            la Política de privacidad, la Política de propiedad intelectual o la
            Declaración de cookies, prevalecerán estos Términos de Uso.
          </p>

          <p>
            Los agentes y empleados de la Compañía no están autorizados a hacer
            modificaciones a los Términos de Uso, ni a hacer representaciones,
            compromisos o garantías adicionales vinculantes para la Compañía.
            Cualquier renuncia a los Términos de Uso aquí presentes por parte de
            la Compañía debe ser por escrito firmada por un funcionario
            autorizado de la Compañía y haciendo referencia expresa a las
            disposiciones aplicables de los Términos de Uso.
          </p>

          <p>
            Si alguna disposición de los Términos de Uso es inválida o
            inaplicable según la ley aplicable, entonces se cambiará e
            interpretará para lograr los objetivos de dicha disposición en la
            mayor medida posible según la ley aplicable, y las disposiciones
            restantes continuarán en pleno vigor y efecto.
          </p>

          <p>
            Usted acepta que, independientemente de cualquier estatuto o ley en
            contrario, cualquier reclamo o causa de acción que surja de o esté
            relacionada con el uso de UltimedPharmacy y/o los Términos de Uso
            debe presentarse dentro de un (1) año después de que surgió dicho
            reclamo o causa de acción o prescribirá para siempre.
          </p>
        </section>

        <section>
          <h2>Entrega de Medicamentos</h2>
          <p>
            Los medicamentos recetados que solicite a través de UltimedPharmacy
            porUltimed Pharmacy serán entregados por un transportista contratado por
          Ultimed Pharmacy. Usted autoriza aUltimed Pharmacy y a los contratistas a entregar
            sus medicamentos en el lugar designado y al representante
            autorizado, si procede. Esta autorización incluirá pero no se
            limitará a: recopilar y acceder a su información personal protegida
            de salud, según sea razonablemente necesario para la prestación del
            servicio solicitado.
          </p>
        </section>

        <section>
          <h2>Aceptación</h2>
          <p>
            USTED RECONOCE QUE HA LEÍDO ESTOS TÉRMINOS DE USO, ENTIENDE LOS
            TÉRMINOS DE USO Y ESTARÁ SUJETO A ESTOS TÉRMINOS DE USO Y
            CONDICIONES. ADEMÁS, RECONOCE QUE ESTOS TÉRMINOS DE USO JUNTO CON LA
            POLÍTICA DE PRIVACIDAD, LA DECLARACIÓN LEGAL, LA DECLARACIÓN DE
            COOKIES Y EL ACUERDO DE LICENCIA, SI CORRESPONDE, REPRESENTAN LA
            DECLARACIÓN COMPLETA Y EXCLUSIVA DEL ACUERDO ENTRE NOSOTROS Y QUE
            REEMPLAZA CUALQUIER PROPUESTA O ACUERDO PREVIO ORAL O ESCRITO, Y
            CUALQUIER OTRA COMUNICACIÓN ENTRE NOSOTROS RELACIONADA CON EL TEMA
            DE ESTE ACUERDO.
          </p>

          <p>
            Agradecemos que se tome el tiempo para leer los Términos de Uso y
            estamos muy contentos de su utilización de UltimedPharmacy.
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfServiceEs;
