import React, { useContext, useState, useEffect, useRef, startTransition } from "react";
import { LanguageContext } from "../LanguageContext";
import "../App.css";
import logo from "../assets/logo.png";
import menu from "../assets/menu.svg";
import langIcon from "../assets/language.png";
import { useTranslation } from "react-i18next";

const Header = ({ languages }) => {
  const { language, onChangeLanguage } = useContext(LanguageContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const dropdownRef = useRef(null);
  const menuRef = useRef(null);
  const contactRef = useRef(null);

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    console.log(`Attempting to change language to: ${lng}`);
    startTransition(() => {
      i18n.changeLanguage(lng).then(() => {
        console.log(`Language changed to: ${lng}`);
        onChangeLanguage(lng); 
      }).catch(err => {
        console.error(`Failed to change language: ${err}`);
      });
    });
  };

  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
    setShowDropdown(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  const handleScrollToSection = (sectionId) => {
    const rootPath = window.location.pathname;
    if (rootPath === "/" || rootPath === "") {
      document.getElementById(sectionId)?.scrollIntoView({ behavior: "smooth" });
    } else {
      window.location.href = `/#${sectionId}`;
    }
    setShowMenu(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="Ultimed Pharmacy" />
      </div>
      <nav className="nav">
        <ul>
          <li>
            <a href="#about" onClick={(e) => {
              e.preventDefault();
              handleScrollToSection('about');
            }}>
              {t('about')}
            </a>
          </li>
          <li>
            <a href="#services" onClick={(e) => {
              e.preventDefault();
              handleScrollToSection('services');
            }}>
              {t('our_services')}
            </a>
          </li>
          <li>
            <a href="#contact" onClick={(e) => {
              e.preventDefault();
              handleScrollToSection('contact');
            }}>
              {t('contact_us')}
            </a>
          </li>
          <li>
            <a href="/tutorials" onClick={(e) => {
              // e.preventDefault();
              // handleScrollToSection('tutorials');
            }}>
              {t('tutorials')}
            </a>
          </li>
          <li>
            <a href="/latest" onClick={(e) => {
              // e.preventDefault();
              // handleScrollToSection('latest');
            }}>
              {t('latestNews')}
            </a>
          </li>
        </ul>
      </nav>
      <div className="lang" ref={dropdownRef}>
        <button
          className="lang-btn"
          onClick={() => setShowDropdown(!showDropdown)}
        >
        </button>
        {showDropdown && (
          <div className="dropdown">
            {languages.map((lang) => (
              <button
                className="dropdown-btn"
                key={lang.code}
                onClick={() => handleLanguageChange(lang.code)}
              >
                {lang.name} <img src={lang.flag} alt={`${lang.name} Flag`} />
              </button>
            ))}
          </div>
        )}
      </div>
      <div className="menu-icon" ref={menuRef}>
        <button className="menu-btn" onClick={() => setShowMenu(!showMenu)}>
          <img src={menu} alt="Menu Icon" />
        </button>
        {showMenu && (
          <div className={`menu-dropdown ${showMenu ? "show" : ""}`}>
            <ul>
              <li>
                <a href="#about" onClick={(e) => {
                  e.preventDefault();
                  handleScrollToSection('about');
                }}>
                  {t('about')}
                </a>
              </li>
              <li>
                <a href="#services" onClick={(e) => {
                  e.preventDefault();
                  handleScrollToSection('services');
                }}>
                  {t('our_services')}
                </a>
              </li>
              <li>
                <a href="#contact" onClick={(e) => {
                  e.preventDefault();
                  handleScrollToSection('contact');
                }}>
                  {t('contact_us')}
                </a>
              </li>
              <li>
                <a href="/tutorials" onClick={(e) => {
                  // e.preventDefault();
                  // handleScrollToSection('tutorials');
                }}>
                  {t('tutorials')}
                </a>
              </li>
              <li>
                <a href="/latest" onClick={(e) => {
                  // e.preventDefault();
                  // handleScrollToSection('latest');
                }}>
                  {t('latestNews')}
                </a>
              </li>
              <hr />
              {languages.map((lang) => (
                <li
                  key={lang.code}
                  onClick={() => handleLanguageChange(lang.code)}
                >
                  <button className="dropdown-btn">
                    {lang.name}{" "}
                    <img src={lang.flag} alt={`${lang.name} Flag`} />
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
