import React from "react";
import { useTranslation } from "react-i18next";
import image from "../assets/pharImg.png";
import "../App.css";

const Main = () => {
  const { t } = useTranslation();

  return (
    <div className="main-container">
      <div className="hero-section">
        <img src={image} alt={t('main_heading')} className="main-image" />
        <div className="main-content">
          <div className="overlay">
            <div className="textcont">
              <div className="logo-T"></div>
              <p className="text-L">Ultimed Pharmacy</p>
            </div>
            <p className="text-logo">
              {t('main_subheading')}
            </p>
          </div>
        </div>{" "}
      </div>
    </div>
  );
};

export default Main;
