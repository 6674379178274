import React, { useEffect, useRef } from 'react';
import Lottie from 'react-lottie';
import animationData from '../assets/wired-outline-1141-email.json';

const LordiconLottie = ({ speed = 1 }) => {
  const lottieRef = useRef();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.setSpeed(speed);
    }
  }, [speed]);

  return (
    <div style={{ width: '50px', height: '50px' }}>
      <Lottie
        options={defaultOptions}
        height={50}
        width={50}
        ref={lottieRef}
      />
    </div>
  );
};

export default LordiconLottie;
