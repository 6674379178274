// src/index.js
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { NylasProvider } from '@nylas/nylas-react';

ReactDOM.render(
  <Suspense fallback={<div>Loading...</div>}>
      <NylasProvider clientId="5zwslf6jc4c3ckwpwy7top121" clientSecret="3rud5qx0yra09n9l5disvuk3r">

    <App />
    </NylasProvider>,
  </Suspense>,
  document.getElementById('root')
);
reportWebVitals();
