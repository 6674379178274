import React, { createContext, useState } from 'react';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');

  const onChangeLanguage = (lang) => {
    setLanguage(lang);
    // Additional logic to change language in your application
  };

  return (
    <LanguageContext.Provider value={{ language, onChangeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
