import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Accordion = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    // Toggle the accordion
    if (index === activeIndex) {
      setActiveIndex(null); // Close accordion if the same title is clicked
    } else {
      setActiveIndex(index); // Open the clicked accordion and close others
    }
  };

  return (
    <div className="accordion">
      <h1>{t("accordion.title")}</h1>
      <p>{t("accordion.subtitle")}</p>
      <div className="accordion-container">
        {t("accordion.faq", { returnObjects: true }).map((item, index) => (
          <div className="wraper" key={index}>
            <div className="wraper2" key={index}>
              <button
                className="accordion-title"
                onClick={() => handleClick(index)}
              >
                {item.question}
              </button>
              <button
                className="accordion-arrow"
                onClick={() => handleClick(index)}
              ></button>
            </div>
            <div
              className={`accordion-content ${
                activeIndex === index ? "open" : ""
              }`}
            >
              {activeIndex === index && <p>{item.answer}</p>}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Accordion;
