import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import "../App.css";
import logo from "../assets/spin-logo-b.svg";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const contactRef = useRef(null);

  const handleScrollToSection = (sectionId) => {
    const rootPath = window.location.pathname;
    if (rootPath === "/" || rootPath === "") {
      document.getElementById(sectionId)?.scrollIntoView({ behavior: "smooth" });
    } else {
      window.location.href = `/#${sectionId}`;
    }
  };

  const privacyPolicyPath = i18n.language === 'es' ? '/privacy-policy-es' : '/privacy-policy';
  const termsOfServicePath = i18n.language === 'es' ? '/terms-of-service-es' : '/terms-of-service';

  return (
    <div className="footer">
      <div className="footer-content">
        <div className="logo-footer">
          <div className="logo-con">
            <img className="logo-f" src={logo} alt={t('footer.title')} />
            <p className="logo-con-text">{t('footer.title')}</p>
          </div>
          <p>{t('footer.description')}</p>
        </div>
        <div className="links">
          <div className="link-section ultimed">
            <h4>{t('footer.ultimed')}</h4>
            <ul>
              <li><a href="/" onClick={(e) => {
                e.preventDefault();
                handleScrollToSection('about');
              }}>{t('footer.home')}</a></li>
              <li><a href="#services" onClick={(e) => {
                e.preventDefault();
                handleScrollToSection('services');
              }}>{t('footer.services')}</a></li>
              <li><a href="#contact" onClick={(e) => {
                e.preventDefault();
                handleScrollToSection('contact');
              }}>{t('footer.contact')}</a></li>
              <li><a href="/tutorials">{t('footer.tutorials')}</a></li>
              <li><a href="/latest">{t('footer.latest')}</a></li>
            </ul>
          </div>
          <div className="link-section policies">
            <h4>{t('footer.policies')}</h4>
            <ul>
              <li><a href={termsOfServicePath}>{t('footer.termsOfService')}</a></li>
              <li><a href={privacyPolicyPath}>{t('footer.privacyPolicy')}</a></li>
            </ul>
          </div>
          {/* <div className="link-section socials">
            <h4>{t('footer.socials')}</h4>
            <div className="social-icons">
              <a href="https://www.linkedin.com"><i className="fa fa-linkedin"></i></a>
              <a href="https://www.facebook.com"><i className="fa fa-facebook"></i></a>
            </div>
          </div> */}
        </div>
        <div className="link-section">
          <div className="contact-info">
            <h4>{t('footer.contactAddress')}</h4>
            <p>{t('footer.address')}</p>
            <h4>{t('footer.email')}</h4>
            <p>{t('footer.phone')}</p>
            <h4>{t('footer.emailAddress')}</h4>
            <p>{t('footer.emailAddressValue')}</p>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>{t('footer.rightsReserved')}</p>
      </div>
    </div>
  );
};

export default Footer;
