import React from "react";
import { useTranslation } from "react-i18next";
import image from "../assets/t-img.svg"; // Adjust the path to your image
import Accordion from "../components/Accordion";

const Tutorials = () => {
  const { t } = useTranslation();

  return (
    <div className="tutorials">
      <div className="hero-section tutorial">
        {/* <img src={image} className="main-image" /> */}
        <div className="main-content">
          <div className="overlay">
            <div className="textcont">
              <div className="logo-T"></div>
              <p className="text-L">{t('tutorials_page.app_by_medplus')}</p>
            </div>
            <p className="text-logo">{t('tutorials_page.easiest_way')}</p>
          </div>
        </div>
      </div>
      <div className="tutorials-container">
        <div className="left">
          <div className="app-image ">
            {/* <img src={appScreenshot} alt="Ultimed Pharmacy App Interface" /> */}
          </div>
          <div className="text-app">
            {t('tutorials_page.app_available')}
          </div>
        </div>

        <div className="tutorials-content">
          <h4>
            {t('tutorials_page.know_what_you_can_do')}
            <br />
            Ultimed Pharmacy by Medplus App
          </h4>
          <p>{t('tutorials_page.app_communication')}</p>
          <ul>
            <li>{t('tutorials_page.features.virtual_communication')}</li>
            <li>{t('tutorials_page.features.detailed_information')}</li>
            <li>{t('tutorials_page.features.medication_profiles')}</li>
            <li>{t('tutorials_page.features.send_prescriptions')}</li>
            <li>{t('tutorials_page.features.delivery_history')}</li>
            <li>{t('tutorials_page.features.institution_census')}</li>
            <li>{t('tutorials_page.features.treatment_alerts')}</li>
            <li>{t('tutorials_page.features.service_alerts')}</li>
          </ul>
        </div>
      </div>
      <div className="wraper3">
      <div className="downloadBanner">
        <h1>{t('tutorials_page.download_now')}</h1>
        <p>{t('tutorials_page.available_platforms')}</p>
        <div className="buttons">
          <a href="https://play.google.com" className="googlePlayButton"></a>
          <a
            href="https://www.apple.com/app-store/"
            className="appStoreButton"
          ></a>
        </div>
        <p className="infoText">
          {t('tutorials_page.fast_communication')}{" "}
          <a className="text-info" href="https://www.ultimedpharmacy.com/request-access">
            {t('tutorials_page.complete_request')}
          </a>
        </p>
        <div className="footer1">ultimedpharmacy</div>
      </div>
      </div>
      <div>
        <div className="update-info-container">
          <div className="update-info-content">
            <div className="left">
              <div className="app-image app2"></div>
              <div className="text-app">{t('tutorials_page.new_features')}</div>
            </div>
            <div className="update-info-text">
              <h2>{t('tutorials_page.stay_updated')}</h2>
              <p>{t('tutorials_page.update_benefits')}</p>
              <ul>
                <li>
                  <strong>{t('tutorials_page.management_center.title')}:</strong>{" "}
                  {t('tutorials_page.management_center.description')}
                </li>
                <li>
                  <strong>{t('tutorials_page.shipping_history.title')}:</strong>{" "}
                  {t('tutorials_page.shipping_history.description')}
                </li>
                <li>
                  <strong>{t('tutorials_page.document_screen.title')}:</strong>{" "}
                  {t('tutorials_page.document_screen.description')}
                </li>
                <li>
                  <strong>{t('tutorials_page.digitized_documents.title')}:</strong>{" "}
                  {t('tutorials_page.digitized_documents.description')}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Accordion></Accordion>
    </div>
  );
};

export default Tutorials;
