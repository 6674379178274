import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import { LanguageProvider } from './LanguageContext';
import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PrivacyPolicyEs from './pages/PrivacyPolicyEs';
import TermsOfService from './pages/TermsOfService';
import TermsOfServiceEs from './pages/TermsOfServiceEs';
import Tutorials from './pages/Tutorials';
import Latest from './pages/Latest';
import en from './assets/en.png';
import esp from './assets/esp.png';
import "./i18n"; // Import the i18n configuration

const languages = [
  { code: 'en', name: 'English', flag: en },
  { code: 'es', name: 'Español', flag: esp },
];

const App = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentLanguage = i18n.language;
    const currentPath = location.pathname;

    // Determine the paths based on the current language
    const privacyPolicyPath = currentLanguage === 'es' ? '/privacy-policy-es' : '/privacy-policy';
    const termsOfServicePath = currentLanguage === 'es' ? '/terms-of-service-es' : '/terms-of-service';

    // Redirect to the correct path based on the current language
    if (currentPath === '/privacy-policy' || currentPath === '/privacy-policy-es') {
      if (currentPath !== privacyPolicyPath) {
        navigate(privacyPolicyPath);
      }
    } else if (currentPath === '/terms-of-service' || currentPath === '/terms-of-service-es') {
      if (currentPath !== termsOfServicePath) {
        navigate(termsOfServicePath);
      }
    }
  }, [i18n.language, location.pathname, navigate]);

  return (
    <div className="App">
      <Header languages={languages} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/privacy-policy-es" element={<PrivacyPolicyEs />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/terms-of-service-es" element={<TermsOfServiceEs />} />
        <Route path="/tutorials" element={<Tutorials />} />
        <Route path="/latest" element={<Latest />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default () => (
  <Router>
    <LanguageProvider>
      <App />
    </LanguageProvider>
  </Router>
);
