// PrivacyPolicy-es.js
import React from "react";

const PrivacyPolicyEs = () => {
  return (
    <div className="privacy-policy">
      <div className="hero-section2">
        {/* <img src={image} alt="" className="main-image" /> */}
        <div className="main-content">
          <div className="overlay">
            <p className="text-logo">POLÍTICA DE PRIVACIDAD </p>
          </div>
        </div>{" "}
      </div>
      <div className="terms">
        <section>
          <h2>En general</h2>
          <p>
            En este documento describimos cómo recopilamos, usamos y manejamos
            su información cuando utiliza nuestros sitios web y servicios
            ("Servicios").
          </p>
          <p>
            En Physician Management Corp. of America, incluidas sus subsidiarias
            o entidades relacionadas/afiliadas (colectivamente la "Compañía",
            "nosotros", "nos" o "nuestro"), valoramos su confianza y respetamos
            su privacidad.
          </p>
          <p>
            La Compañía se esfuerza por apoyar a los usuarios como usted
            ofreciendo nuestros Servicios, respetando sus expectativas de
            privacidad y protegiendo su Información Personal.
          </p>
          <p>
            La Compañía está obligada por las regulaciones de privacidad
            emitidas bajo la Ley de Portabilidad y Responsabilidad del Seguro
            Médico de 1996 (HIPAA) a mantener la privacidad de la información
            médica de nuestros pacientes y a proporcionar a los pacientes un
            aviso de nuestros deberes legales y prácticas de privacidad con
            respecto a su información médica. Proteger la confidencialidad de su
            información personal ha sido, y siempre será, una prioridad para la
            Compañía. Queremos que comprenda las medidas que tomamos para
            proteger su información de salud y las situaciones en las que
            podríamos compartirla en el curso de brindarle su atención médica.
          </p>
          <p>
            La PHI se relaciona con registros físicos, mientras que ePHI es
            cualquier PHI que se crea, almacena, transmite o recibe
            electrónicamente.
          </p>
          <p>
            La PHI solo se relaciona con información sobre pacientes o miembros
            del plan de salud. No incluye información contenida en registros
            educativos y de empleo, que incluya información de salud mantenida
            por una entidad cubierta por HIPAA en su calidad de empleador.
          </p>
          <p>
            La PHI solo se considera PHI cuando un individuo puede ser
            identificado a partir de la información. Si todos los
            identificadores se eliminan de los datos de salud, deja de ser
            información de salud protegida y las restricciones de uso y
            divulgaciones de la Regla de privacidad de HIPAA ya no se aplican.
          </p>
          <p>
            Por "Información personal" o "Información comercial" (colectivamente
            la "Información personal"), nos referimos a la información sobre un
            individuo o negocio identificable, como el nombre de una persona, la
            dirección de correo electrónico, la dirección residencial, el número
            de teléfono y, en algunos casos, información más confidencial, como,
            por ejemplo, entre otros, género, demografía, entre otros.
          </p>
          <p>
            En algunos casos, podemos recibir información sobre usted de otras
            fuentes, o de terceros de los que obtenemos datos, y combinar estos
            datos con información que ya tenemos sobre usted o su negocio. Esto
            nos ayuda a actualizar, ampliar, analizar nuestros registros, entre
            otros.
          </p>
          <p>
            Para demostrar nuestro compromiso de proteger su privacidad, hemos
            desarrollado esta Política de privacidad, que describe cómo
            recopilaremos, usaremos, divulgaremos, conservaremos y protegeremos
            la Información personal para proporcionarle nuestros Servicios
            ofrecidos y mantenidos por nosotros y nuestros afiliados de vez en
            cuando. Esta Política de privacidad se aplica a todos los dominios
            cuyos dominios son propiedad y están operados por la Compañía.
          </p>
          <p>
            AL REGISTRARSE PARA OBTENER UNA CUENTA DE USUARIO O AL UTILIZAR EL
            SITIO, USTED CONFIRMA QUE HA LEÍDO, ENTENDIDO Y ACEPTA CUMPLIR CON
            ESTA POLÍTICA. LEA DETENIDAMENTE ESTA POLÍTICA, YA QUE DESCRIBE
            NUESTRAS POLÍTICAS Y PRÁCTICAS DE PRIVACIDAD. SI NO ESTÁ DE ACUERDO
            CON NUESTRAS POLÍTICAS Y PRÁCTICAS DE PRIVACIDAD, NO UTILICE EL
            SITIO.
          </p>
          <p>ESTA POLÍTICA PUEDE CAMBIAR DE VEZ EN CUANDO SIN PREVIO AVISO.</p>
        </section>

        <section>
          <h2>Alcance</h2>
          <p>
            A los efectos de esta Política de privacidad, el término "Sitio"
            significa este sitio web y todas las Aplicaciones, software, páginas
            web relacionadas, redes sociales y sitios web relacionados operados
            por afiliados o divisiones de la Compañía, pero no incluye ningún
            sitio web de terceros que esté vinculado o pueda vincularse desde
            este sitio web, ya sea que dichos sitios web de terceros se utilicen
            o no en relación con los Servicios. "Aplicaciones de la Compañía"
            significa el Sitio y otras aplicaciones que proporcionan acceso a
            los Servicios ofrecidos por la Compañía y sus afiliados de vez en
            cuando. Cuando esta Política no se aplica, debe leer la política de
            privacidad aplicable y los términos relacionados publicados en el
            sitio web, la aplicación u otro servicio que esté utilizando antes
            de proporcionarles su información.
          </p>
        </section>
        <section>
          <h2>Responsabilidad</h2>
          <p>
            La Compañía ha designado un Agente Designado que es responsable de
            recibir sus preguntas relacionadas con la privacidad y de
            proporcionarle información sobre nuestras prácticas de privacidad.
          </p>
        </section>
        <section>
          <h2>Agente designado por la empresa:</h2>
          <p>
            Correo electrónico: complianceUltimedPharmacy.com :
            complianceUltimedPharmacy.com
          </p>
        </section>
        <section>
          <h2>Propósitos de identificación</h2>
          <p>
            La Compañía recopila, utiliza y divulga Información Personal solo
            con el fin de proporcionarle nuestros Servicios y para otros fines
            auxiliares consentidos por usted como usuario.
          </p>
          <p>
            Procesamos Información Personal para operar, mejorar, comprender y
            personalizar nuestros Servicios. Más específicamente, la Compañía
            recopila, usa y divulga información (que puede incluir Información
            personal) para los siguientes propósitos:
          </p>
          <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
            <li>Para proporcionarle nuestros Servicios.</li>
            <li>Crear y gestionar perfiles de usuario.</li>
            <li>
              Para promocionar u ofrecerle productos, servicios y ofertas
              (incluidos los Servicios nuevos y otros que ofrecemos nosotros,
              nuestros patrocinadores y socios) que puedan ser relevantes para
              usted.
            </li>
            <li>
              Para ponernos en contacto con usted con fines de información de
              productos, actualizaciones de servicios, notificaciones
              relacionadas con los Servicios, boletines informativos y mensajes
              personalizados de oportunidades de ahorro.
            </li>
            <li>
              Monitorear el uso del sistema, el rendimiento del servidor y del
              software, mejorar el diseño del sistema, crear puntos de
              referencia y realizar análisis de tendencias.
            </li>
            <li>
              Proporcionar apoyo y asistencia para los Servicios. Para ayudarle
              con problemas de soporte técnico. Es importante recordar que la
              mayoría de los problemas técnicos se pueden resolver sin que un
              representante de servicio al cliente de la Compañía vea su
              Información personal.
            </li>
            <li>Para procesar pedidos.</li>
            <li>
              Para personalizar el contenido del sitio web y las comunicaciones
              en función de sus preferencias.
            </li>
            <li>Para responder a las consultas de los usuarios.</li>
            <li>Para cumplir con las solicitudes de los usuarios.</li>
            <li>Para cumplir con obligaciones contractuales o legales.</li>
            <li>
              Para cumplir con cualquier ley, regulación, orden judicial,
              citaciones u otro proceso legal o investigación y para protegernos
              a nosotros mismos y a otras personas de daños.
            </li>
            <li>
              Para ayudar en la diligencia debida relacionada con cualquier
              acción corporativa, como un financiamiento, fusión, venta o
              desinversión, siempre que se tomen salvaguardas razonables para
              proteger la confidencialidad de la Información personal en nuestra
              posesión.
            </li>
            <li>Para resolver disputas.</li>
            <li>
              Para proteger o disuadir acciones fraudulentas, ilegales o
              perjudiciales.
            </li>
            <li>
              Para hacer cumplir nuestros "Documentos Legales": Términos de Uso,
              Política de Privacidad y el Acuerdo de Suscripción, entre otros,
              para el Sitio.
            </li>
          </ul>
          <p>
            Solo procesaremos su información personal si tenemos una base legal
            para hacerlo. Las bases legales para el procesamiento incluyen el
            consentimiento, la necesidad contractual y nuestros "intereses
            legítimos" o el interés legítimo de otros, como se describe a
            continuación.
          </p>
          <p>
            Necesidad contractual: Procesamos su nombre y apellido, dirección de
            correo electrónico, ubicación e información de tarjeta de crédito /
            débito como una cuestión de "necesidad contractual", lo que
            significa que necesitamos procesar los datos para realizar bajo
            nuestros "Documentos legales" con usted, lo que nos permite
            proporcionarle los Servicios. Cuando procesamos datos debido a una
            necesidad contractual, el hecho de no proporcionar dicha Información
            personal resultará en su incapacidad para usar algunas o todas las
            partes de los Servicios que requieren dichos datos.
          </p>
          <p>
            Interés legítimo: También procesamos la información personal
            anterior cuando creemos que promueve nuestros intereses legítimos,
            ejemplos de los cuales incluyen:
          </p>
          <ul>
            <li>
              Operación y mejora de nuestro negocio, productos y servicios
            </li>
            <li>Comercialización de nuestros productos y servicios</li>
            <li>Prestación de atención al cliente</li>
            <li>Protección contra fraudes o amenazas de seguridad</li>
            <li>Cumplimiento de obligaciones legales</li>
            <li>Realización de transacciones corporativas</li>
          </ul>
          <p>
            Consentimiento: En algunos casos, procesamos la Información personal
            en función del consentimiento que nos otorga expresamente en el
            momento en que recopilamos dichos datos. Cuando procesamos
            información personal basada en su consentimiento, se le indicará
            expresamente en el momento y momento de la recopilación.
          </p>
          <p>
            Otros motivos de procesamiento: De vez en cuando, es posible que
            también necesitemos procesar información personal para cumplir con
            una obligación legal, si es necesario para proteger los intereses
            vitales de usted u otros interesados, o si es necesario para una
            tarea realizada en interés público.
          </p>
        </section>

        <section>
          <h2>Consentimiento</h2>
          <p>
            Recopilamos Información Personal directamente de usted u otros
            terceros. Usamos y divulgamos la Información Personal solo como se
            describe en esta política y nuestros "Documentos Legales".
          </p>
          <p>
            Retirar su consentimiento no se aplicará a las acciones que la
            Compañía ya haya tomado en base a su consentimiento previo.
          </p>
          <p>
            Para utilizar ciertos Servicios, es posible que se le solicite que
            proporcione información personal relacionada con usted y sus
            empleados, agentes, contratistas y representantes de vez en cuando.
            Usted reconoce y nos declara que solo recopila, usa y divulga
            información personal de conformidad con las leyes de privacidad
            aplicables. Además, declara y nos garantiza que ha obtenido todos
            los consentimientos requeridos de sus empleados, agentes,
            contratistas y representantes cuya información personal se nos
            divulgará a nosotros o a nuestros proveedores de servicios externos
            en relación con los Servicios. Además, acepta indemnizar y eximir de
            responsabilidad a la Compañía, sus afiliados, subsidiarias, socios,
            proveedores de servicios, proveedores y contratistas y cada uno de
            sus respectivos funcionarios, directores, agentes y empleados
            (colectivamente, las "Partes"), por cualquier pérdida, costo, queja,
            daño, reclamo o responsabilidad que surja de su recopilación, uso y
            divulgación de información personal relacionada con sus empleados,
            agentes, contratistas y representantes.
          </p>
        </section>

        <section>
          <h2>Recopilación de información de identificación personal</h2>
          <p>
            La Compañía debe recopilar parte de su información de identificación
            personal para proporcionarle, respaldar, mejorar y comercializar el
            Sitio y el Contenido de la Compañía. Algunas de las formas en que la
            Compañía puede recopilar dicha información incluyen información que
            usted proporciona a la Compañía directamente, información sobre
            usted que la Compañía recopila automáticamente a partir de datos de
            registro, direcciones IP y datos de usuario agregados, cookies y
            tecnologías similares, e información que le brinda a la Compañía
            sobre sus contactos y sus historiales de chat, si corresponde. La
            Compañía solo utilizará su información de identificación personal
            cuando la ley le permita a la Compañía hacerlo y de las maneras
            descritas en esta Política de privacidad.
          </p>

          <p>
            La Compañía recopila, procesa, almacena y transfiere información de
            identificación personal que usted envía a través del Sitio y sus
            otros servicios y Contenido, que incluyen:
          </p>

          <p>
            <strong>Información que usted nos proporciona.</strong> Recibimos y
            almacenamos cualquier información que nos proporcione a sabiendas.
            Por ejemplo, recopilamos información personal como su nombre,
            dirección de correo electrónico, nombre de usuario, información de
            seguro, número de seguro social e historial de salud. Podemos
            desidentificar su información personal para que no pueda ser
            identificado individualmente y proporcionar esa información a
            nuestros socios.
          </p>

          <p>
            Si nos ha proporcionado un medio para contactarlo, podemos usar
            dichos medios para comunicarnos con usted. Por ejemplo, podemos
            enviarle ofertas promocionales o comunicarnos con usted sobre su uso
            de los Servicios. Además, podemos recibir una confirmación cuando
            abra un mensaje nuestro. Esta confirmación nos ayuda a hacer que
            nuestras comunicaciones con usted sean más interesantes y mejorar
            nuestros servicios. Si no desea recibir nuestras comunicaciones,
            indique su preferencia siguiendo el enlace para darse de baja
            proporcionado en cada correo electrónico. Tenga en cuenta que, si no
            desea recibir avisos legales de nuestra parte, esos avisos legales
            seguirán rigiendo su uso de los Servicios, y usted es responsable de
            revisar dichos avisos legales para detectar cambios.
          </p>

          <p>
            <strong>Información comercial.</strong> Esto incluye la información
            proporcionada en el curso de la relación contractual o de cliente
            entre usted o su organización y nosotros, o proporcionada
            voluntariamente por usted o su organización.
          </p>

          <p>
            <strong>Preferencias de Marketing y Comunicaciones.</strong> Estos
            incluyen sus preferencias para recibir marketing de nosotros y de
            terceros y sus preferencias de comunicación. Le ofrecemos control
            sobre los diversos tipos de comunicaciones opcionales que ponemos a
            su disposición, incluidas las capacidades de inclusión y exclusión.
            A veces, también recibirá comunicaciones obligatorias de nuestro
            Sitio cuando entreguemos los servicios y el Contenido que solicite o
            necesite para informarle sobre avisos importantes sobre el Sitio. En
            tales casos, su única opción para optar por no recibir
            comunicaciones obligatorias es cancelar su cuenta de usuario con
            nosotros.
          </p>

          <p>
            <strong>Información de contacto, comentarios y consultas.</strong>{" "}
            Cuando nos envía comentarios, se comunica con nosotros para obtener
            asistencia o nos hace preguntas, podemos recopilar su nombre y
            apellido, dirección de correo electrónico, número de teléfono y / u
            otra información de contacto para responder a sus comentarios,
            brindar soporte o responder sus preguntas. Esto incluye registros y
            copias de su correspondencia (incluidas direcciones de correo
            electrónico y números de teléfono) con nosotros, si corresponde.
          </p>

          <p>
            <strong>Información recopilada automáticamente.</strong> Cada vez
            que interactúa con nuestros Servicios, recibimos y registramos
            automáticamente información en los registros de nuestro servidor
            desde su navegador, incluida su dirección IP, información de
            "cookies" y la página que solicitó. Las "cookies" son
            identificadores que transferimos a su computadora o dispositivo
            móvil que nos permiten reconocer su navegador o dispositivo móvil y
            nos dicen cómo y cuándo se visitan las páginas y funciones de
            nuestros Servicios y por cuántas personas. Es posible que pueda
            cambiar las preferencias en su navegador o dispositivo móvil para
            evitar o limitar la aceptación de cookies por parte de su
            computadora o dispositivo, pero esto puede impedirle aprovechar
            algunas de nuestras funciones. Nuestros socios publicitarios también
            pueden transmitir cookies a su computadora o dispositivo, cuando
            hace clic en los anuncios que aparecen en los Servicios. Además, si
            hace clic en un enlace a un sitio web de terceros, dicho tercero
            también puede transmitirle cookies. Esta Política de Privacidad no
            cubre el uso de cookies por parte de terceros.
          </p>

          <p>
            <strong>
              Categorías especiales de información y datos sensibles.
            </strong>{" "}
            Es posible que le solicitemos que proporcione categorías especiales
            de datos personales e información para acceder y utilizar nuestro
            Sitio. Los datos personales sensibles y la información generalmente
            se definen por ley para incluir detalles sobre su raza, etnia,
            creencias religiosas o filosóficas, vida sexual, orientación sexual,
            opiniones políticas, afiliación sindical, salud y / o datos
            genéticos y biométricos y la recopilación de dichos datos e
            información sujetos a ciertas protecciones. Tampoco recopilamos ni
            requerimos intencionalmente ninguna información de usted sobre
            condenas y delitos penales y solicitamos que no nos la proporcione.
            Solo debe compartir Datos personales e información confidenciales
            con personas de confianza. No somos responsables de ningún daño o
            pérdida que ocurra como resultado de su decisión de compartir dichos
            datos con nosotros o con terceros a través del Sitio.
          </p>

          <p>
            No obstante lo anterior, podemos utilizar los Datos Personales
            Sensibles y la Información que recibimos para cumplir con las
            solicitudes legales de los usuarios y para hacer cumplir nuestros
            "Documentos Legales", lo que puede incluir la eliminación de
            cualquier Dato Personal Sensible e Información que envíe que se
            considere ilegal o innecesaria para su acceso y uso del Sitio.
          </p>

          <p>
            Nos reservamos el derecho de eliminar, a nuestra entera discreción,
            cualquier información (como nombres de usuario, fotos de perfil,
            nombres, etc.) que proporcione cuando se registre o utilice el Sitio
            o cualquier servicio en el mismo, si creemos que es inapropiado o
            viola nuestros "Documentos legales". Tenemos funcionalidad de
            comentarios en el Sitio que le permite enviar comentarios o
            sugerencias. Podemos recopilar su nombre o nombre de usuario y
            dirección de correo electrónico para hacer un seguimiento con usted
            o agradecerle por una sugerencia.
          </p>
        </section>

        <section>
          <h2>¿Cómo y con quién compartimos sus datos?</h2>
          <p>
            <strong>Información personal de salud</strong>
          </p>

          <p>
            Bajo la Regla de Privacidad de HIPAA, se nos permite, pero no se nos
            exige, usar y divulgar PHI, sin la autorización de un individuo,
            para los siguientes propósitos o situaciones:
          </p>

          <ul>
            <li>
              Se nos permite divulgar la PHI a la persona que es el sujeto de la
              información.
            </li>
            <li>
              <strong>
                Tratamiento, pago y operaciones de atención médica
              </strong>
            </li>
            <ul>
              <li>
                <strong>Tratamiento</strong> es la provisión, coordinación o
                administración de servicios de atención médica y servicios
                relacionados para un individuo por parte de un proveedor de
                atención médica.
              </li>
              <li>
                <strong>Pago</strong> abarca las actividades de un plan de salud
                para obtener primas, determinar o cumplir con las
                responsabilidades de cobertura y provisión de beneficios, y
                proporcionar u obtener reembolso por la atención médica brindada
                a un individuo. El pago también incluye las actividades de un
                proveedor de atención médica para obtener el pago o ser
                reembolsado por la prestación de atención médica a un individuo.
              </li>
              <li>
                <strong>Las operaciones de atención médica</strong> son
                cualquiera de las siguientes actividades:
              </li>
              <ul>
                <li>
                  Actividades de evaluación y mejora de la calidad, incluida la
                  gestión de casos y la coordinación de la atención;
                </li>
                <li>
                  Actividades de aseguramiento de competencias, incluida la
                  evaluación del desempeño, acreditación y acreditación de
                  proveedores o planes de salud;
                </li>
                <li>
                  Realizar o organizar revisiones médicas, auditorías o
                  servicios legales, incluidos programas de detección y
                  cumplimiento de fraudes y abusos;
                </li>
                <li>
                  Funciones de seguro especificadas, como suscripción,
                  calificación de riesgos y reaseguro de riesgos;
                </li>
                <li>
                  Planificación, desarrollo, gestión y administración de
                  negocios; y
                </li>
                <li>
                  Gestión empresarial y actividades administrativas generales de
                  la entidad, incluyendo pero no limitado a:
                </li>
                <ul>
                  <li>
                    Desidentificación de la información de salud protegida
                    (eliminación de información específica sobre un paciente que
                    se puede usar sola o en combinación con otra información
                    para identificar a ese paciente);
                  </li>
                  <li>
                    Crear un conjunto de datos limitado (información de atención
                    médica identificable que las entidades cubiertas pueden
                    compartir con ciertas entidades con fines de investigación,
                    actividades de salud pública y operaciones de atención
                    médica, sin obtener la autorización previa por escrito del
                    paciente); y
                  </li>
                  <li>
                    Cierta recaudación de fondos en beneficio de la entidad
                    cubierta.
                  </li>
                </ul>
              </ul>
            </ul>
          </ul>

          <p>
            <strong>Para la investigación</strong>. Si tiene un diagnóstico que
            se utiliza para fines de investigación y usted ha proporcionado su
            consentimiento, podemos usar la PHI y otros datos de usted y
            combinarlos con la información de otros pacientes, lo que nos
            permite realizar estudios e investigaciones para fines médicos,
            científicos y educativos.
          </p>

          <p>
            <strong>
              Si tenemos que hacer cumplir nuestros "Documentos Legales"
            </strong>
            . La Compañía se reserva el derecho de divulgar su información de
            identificación personal a los proveedores de atención médica, a los
            contratistas, agentes o proveedores de servicios de la Compañía, o
            cuando creemos que es necesario para cumplir con las leyes y
            regulaciones aplicables o para hacer cumplir nuestros "Documentos
            Legales".
          </p>

          <p>
            <strong>
              Cuando sea necesario para la prestación de servicios
            </strong>
            . Podemos compartir su información con nuestros socios comerciales
            que brindan servicios relacionados con los servicios que usted
            solicita y utilizan la información solo para esos fines, y / o con
            otros proveedores de servicios para realizar funciones y tareas en
            nombre de la Compañía. También podemos compartir su información de
            identificación personal con fines de investigación médica, informes
            estadísticos, campañas de marketing y publicidad, y otras
            actividades comerciales relacionadas, siempre que sea conforme a la
            legislación aplicable y con las restricciones necesarias.
          </p>

          <p>
            <strong>Con otros proveedores</strong>. Podemos compartir su
            información con proveedores de atención médica, compañías de
            seguros, proveedores de atención médica, agencias gubernamentales y
            otros socios relevantes, siempre que haya obtenido el consentimiento
            o exista una necesidad legítima para hacerlo.
          </p>

          <p>
            <strong>Transferencias corporativas</strong>. Podemos divulgar su
            información en el contexto de una transacción corporativa, como una
            fusión, adquisición, venta de activos o una reestructuración. En tal
            caso, nos aseguraremos de que los detalles sobre el uso y la
            protección de su información personal se manejen de acuerdo con
            nuestras prácticas y con la legislación aplicable.
          </p>
        </section>

        <section>
          <h2>Seguridad</h2>
          <p>
            La Compañía toma medidas razonables y adecuadas para proteger la
            información de identificación personal que se recopila a través del
            Sitio y sus servicios. Aunque hacemos esfuerzos razonables para
            proteger su información, no podemos garantizar la seguridad de
            ninguna información transmitida a través de Internet o almacenada en
            nuestros sistemas.
          </p>

          <p>
            La Compañía utiliza estándares de seguridad generalmente aceptados
            para proteger la información contra el acceso no autorizado, la
            alteración, la divulgación o la destrucción. Sin embargo, ningún
            método de transmisión a través de Internet o método de
            almacenamiento electrónico es 100% seguro. Por lo tanto, no podemos
            garantizar la seguridad absoluta.
          </p>
        </section>

        <section>
          <h2>Retención de Datos</h2>
          <p>
            La Compañía retendrá su información de identificación personal
            durante el tiempo que sea necesario para cumplir con los fines
            descritos en esta Política de privacidad, o según lo requiera la
            legislación aplicable.
          </p>

          <p>
            Si su información ya no es necesaria para los fines descritos o si
            solicita la eliminación de su información, tomaremos las medidas
            necesarias para eliminar o desidentificar su información, de acuerdo
            con nuestras políticas internas y las leyes aplicables.
          </p>
        </section>

        <section>
          <h2>Sus Derechos</h2>
          <p>
            Según la legislación aplicable, usted puede tener ciertos derechos
            con respecto a su información de identificación personal, que pueden
            incluir el derecho a acceder a, corregir, actualizar o eliminar la
            información que tenemos sobre usted.
          </p>

          <p>
            Si desea ejercer cualquiera de estos derechos o tiene preguntas
            sobre su información personal, puede ponerse en contacto con
            nosotros utilizando la información de contacto proporcionada en esta
            Política de privacidad.
          </p>
        </section>

        <section>
          <h2>Enlaces a otros sitios web</h2>
          <p>
            El Sitio puede contener enlaces a otros sitios web que no están
            operados por la Compañía. No somos responsables de la privacidad ni
            del contenido de dichos sitios web. Le recomendamos que revise las
            políticas de privacidad de cualquier sitio web de terceros antes de
            proporcionarles información personal.
          </p>
        </section>

        <section>
          <h2>Modificaciones a esta Política de Privacidad</h2>
          <p>
            La Compañía puede actualizar esta Política de privacidad de vez en
            cuando. Le notificaremos cualquier cambio material a esta Política
            de privacidad publicando la nueva versión en el Sitio y actualizando
            la fecha de la última modificación. Le recomendamos que revise
            periódicamente esta Política de privacidad para estar informado
            sobre cómo estamos protegiendo su información.
          </p>
        </section>

        <section>
          <h2>Información de Contacto</h2>
          <p>
            Si tiene alguna pregunta sobre esta Política de privacidad o
            nuestras prácticas de privacidad, puede ponerse en contacto con
            nosotros a través de la información de contacto proporcionada en el
            Sitio.
          </p>
        </section>

        <section>
          <h2>Información Personal</h2>
          <p>
            Compartimos información personal con proveedores, proveedores de
            servicios externos y agentes que trabajan en nuestro nombre y nos
            brindan servicios relacionados con los fines descritos en los
            "Documentos Legales". Estas partes incluyen:
          </p>

          <ul>
            <li>Procesadores de pagos;</li>
            <li>Proveedores de servicios de prevención de fraude;</li>
            <li>Redes publicitarias y socios;</li>
            <li>Proveedores de servicios de análisis;</li>
            <li>Proveedores de servicios de hosting.</li>
          </ul>

          <p>
            También compartimos información personal cuando es necesario para
            completar una transacción iniciada o autorizada por usted o
            proporcionarle un producto o servicio que haya solicitado, incluso
            con otros usuarios (cuando publique información públicamente o según
            sea necesario para procesar una transacción iniciada o autorizada
            por usted a través de los Servicios).
          </p>

          <p>
            También compartimos información personal cuando creemos que es
            necesario para:
          </p>

          <ul>
            <li>
              Cumplir con la ley aplicable o responder a un proceso legal
              válido, incluso de las fuerzas del orden u otras agencias
              gubernamentales;
            </li>
            <li>
              Protegernos a nosotros, a nuestra empresa o a nuestros usuarios,
              por ejemplo, para hacer cumplir nuestros "Documentos Legales",
              prevenir el spam u otras comunicaciones no deseadas e investigar o
              proteger contra el fraude; y
            </li>
            <li>Mantener la seguridad de nuestros productos y servicios.</li>
          </ul>

          <p>
            También podemos compartir información personal con terceros sin su
            consentimiento previo.
          </p>

          <p>
            Además, si decidimos comprar o vender activos, la información del
            usuario suele ser uno de los activos comerciales transferidos.
            Además, si nosotros, o sustancialmente todos nuestros activos,
            fuéramos adquiridos, o si cerramos el negocio o entramos en
            bancarrota, la información del usuario sería uno de los activos que
            se transferirían o adquirirían por un tercero, y compartiríamos
            información personal con la parte que está adquiriendo nuestros
            activos. Usted reconoce que tales transferencias pueden ocurrir, y
            que cualquier adquirente de nosotros o de nuestros activos puede
            continuar utilizando su información personal como se establece en
            esta política.
          </p>
        </section>

        <section>
          <h2>¿Cuánto tiempo conservamos su información personal?</h2>
          <p>
            Retenemos información personal sobre usted durante el tiempo que
            tenga una cuenta abierta con nosotros o según sea necesario para
            proporcionarle Servicios. En algunos casos, conservamos la
            información personal durante más tiempo, si hacerlo es necesario
            para cumplir con nuestras obligaciones legales, resolver disputas o
            cobrar las tarifas adeudadas, o si la ley, regla o regulación
            aplicable lo permite o exige de otra manera, como para fines de
            marketing.
          </p>
        </section>

        <section>
          <h2>¿Qué medidas de seguridad utilizamos?</h2>
          <p>
            La Regla de Seguridad HIPAA requiere que las entidades cubiertas se
            protejan contra amenazas razonablemente anticipadas a la seguridad
            de la PHI. Las entidades cubiertas deben implementar salvaguardas
            para garantizar la confidencialidad, integridad y disponibilidad de
            la PHI, aunque HIPAA no es específica de la tecnología y las
            salvaguardas exactas que deben implementarse se dejan a la
            discreción de la entidad cubierta.
          </p>

          <p>
            Buscamos proteger la PHI y la información personal mediante la
            implementación de las mejores prácticas de la industria apropiadas
            para la sensibilidad de su información personal. Utilizamos
            salvaguardas administrativas, técnicas y físicas para proteger su
            información personal contra pérdida, robo y acceso, uso,
            divulgación, copia, modificación, eliminación o destrucción no
            autorizados, de acuerdo con los requisitos legales aplicables y las
            mejores prácticas de la industria.
          </p>

          <p>
            Nos aseguramos de que cualquier tercero que actúe en nuestro nombre
            con respecto a su información personal mantenga salvaguardas
            razonables y apropiadas. Por ejemplo, estos terceros clasifican los
            datos de la cuenta del cliente como "súper sensibles". Esto
            significa que el acceso a dichos datos está estrictamente limitado
            en función de las necesidades comerciales y estos datos no se pueden
            transmitir sin el uso de métodos de cifrado aprobados.
          </p>

          <h2>
            Acceso, Actualización y Eliminación de su Información Personal
          </h2>
          <p>
            En ausencia de circunstancias excepcionales, le ofrecemos acceso
            razonable y control sobre su información personal en cualquier
            momento, sin cargo. Infórmese sobre sus derechos revisando la lista
            a continuación. Puede utilizar los detalles de “Contáctenos” al
            final de esta Política para ejercer sus derechos y opciones.
          </p>

          <p>
            Intentamos responder a todas las solicitudes legítimas con respecto
            a su información personal dentro de un mes. Ocasionalmente, podría
            llevarnos más de un mes si su solicitud es particularmente compleja
            o si ha realizado una serie de solicitudes. En tales casos, le
            notificaremos y lo mantendremos actualizado. Los plazos descritos
            aquí no se aplican a nuestros tiempos de respuesta para abordar
            solicitudes ordinarias de servicio al cliente y soporte no
            relacionadas con el ejercicio de los derechos de acceso a la
            información personal.
          </p>

          <p>
            Aunque hacemos esfuerzos comercialmente razonables para proporcionar
            a las personas acceso a su información personal y para responder a
            sus solicitudes, puede haber circunstancias en las que necesitemos
            solicitarle más información para identificar su cuenta y podemos
            pedirle que demuestre su identidad o es posible que no podamos
            proporcionar acceso, incluidos, entre otros: cuando la información
            contenga privilegios legales, comprometería la privacidad de otros u
            otros derechos legítimos, cuando la carga o el gasto de proporcionar
            acceso sería desproporcionado con respecto a los riesgos para la
            privacidad del individuo en el caso en cuestión o cuando sea
            propiedad comercial. Si determinamos que el acceso debe restringirse
            en cualquier caso en particular, le proporcionaremos una explicación
            de por qué se ha tomado esa determinación y un punto de contacto
            para cualquier consulta adicional. Para proteger su privacidad,
            tomaremos medidas comercialmente razonables para verificar su
            identidad antes de otorgar acceso o realizar cualquier cambio en su
            información personal.
          </p>

          <p>
            <strong>1. Solicitud de acceso.</strong> Tiene derecho a solicitar
            una copia de la información personal que tenemos sobre usted, así
            como a preguntar sobre el origen, los destinatarios y los fines de
            esos datos. Sin embargo, hay ciertas excepciones a este derecho,
            para que podamos denegarle el acceso según lo exija la ley o para
            proteger los derechos legales de otros.
          </p>

          <p>
            <strong>2. Solicitar corrección.</strong> Tiene derecho a solicitar
            la corrección de la información personal que tenemos sobre usted.
            Esto le permite corregir cualquier dato incompleto o inexacto que
            tengamos sobre usted, aunque es posible que necesitemos verificar la
            exactitud de los nuevos datos que nos proporcione. Nuestro objetivo
            es mantener su información personal precisa, actualizada y completa.
            Póngase en contacto con nosotros si cree que su información no es
            precisa o cambia. Puede editar parte de la información de su cuenta
            a través del Sitio.
          </p>

          <p>
            <strong>
              3. Derecho a la eliminación y eliminación de su información
              personal.
            </strong>{" "}
            Es posible que tenga el derecho legal de solicitar que eliminemos su
            información personal cuando ya no sea necesaria para los fines para
            los que se recopiló, o cuando, entre otras cosas, su información
            personal haya sido procesada ilegalmente.
          </p>

          <p>
            También podemos decidir eliminar su información personal si creemos
            que es incompleta, inexacta o que nuestro almacenamiento continuo de
            su información personal es contrario a nuestras obligaciones legales
            u objetivos comerciales. Cuando eliminamos información personal, se
            eliminará de nuestros servidores y bases de datos activos y de
            nuestros Servicios, pero puede permanecer en nuestros archivos
            cuando no sea práctico o posible eliminarla. También podemos retener
            su información personal según sea necesario para cumplir con
            nuestras obligaciones legales, resolver disputas o hacer cumplir
            cualquier acuerdo, como información limitada sobre su dispositivo
            que puede conservarse con el fin de detectar fraudes y hacer cumplir
            nuestros "Documentos Legales".
          </p>

          <p>
            <strong>4. Derecho de oposición.</strong> En ciertas circunstancias,
            según lo permitido por la ley aplicable, tiene derecho a oponerse al
            procesamiento de su información personal y a solicitarnos que
            borremos o restrinjamos nuestro uso de su información personal. Si
            desea que dejemos de usar su información personal, comuníquese con
            nosotros y le informaremos si podemos aceptar su solicitud.
          </p>

          <p>
            <strong>5. Derecho a la portabilidad.</strong> Tiene derecho a
            solicitar que parte de su información personal se le proporcione a
            usted o a un tercero autorizado en un formato de uso común y legible
            por máquina (como .txt). Si requiere la transferencia directa de
            datos a otra parte responsable, esto solo se hará en la medida en
            que sea técnicamente posible.
          </p>

          <p>
            <strong>6. Derecho a retirar el consentimiento.</strong> Si ha dado
            su consentimiento para la recopilación, el procesamiento y la
            transferencia de su información personal, tiene derecho a retirar
            completamente su consentimiento. Intentamos cumplir con todas las
            solicitudes razonables y legítimas. Como se discutió anteriormente,
            puede modificar los permisos y la configuración de su dispositivo y
            el Sitio para limitar el acceso a cierta información. Para retirar
            su consentimiento con respecto a los correos electrónicos y otras
            comunicaciones, puede optar por no recibir más correos electrónicos
            de marketing siguiendo las instrucciones de exclusión que se
            encuentran en la parte inferior de dichos correos electrónicos.
          </p>

          <p>
            <strong>7. Derecho a presentar una queja.</strong> Tiene derecho a
            presentar una queja ante una autoridad de protección de datos, como
            el Comité de Protección de Datos de la Unión Europea o el
            comisionado de privacidad en su jurisdicción, si considera que
            nuestro procesamiento de su información personal no cumple con las
            leyes de protección de datos aplicables.
          </p>

          <p>
            Recuerde que, de conformidad con la legislación aplicable, también
            puede tener el derecho a presentar una queja ante la autoridad de
            protección de datos correspondiente si cree que nuestra
            recopilación, almacenamiento o procesamiento de su información
            personal no cumple con la ley. Para cualquier solicitud relacionada
            con sus derechos de privacidad, comuníquese con nosotros utilizando
            la información de contacto proporcionada a continuación.
          </p>
        </section>

        <section>
          <h2>Limitación de la Recopilación</h2>
          <p>
            La Compañía limita la recopilación de Información Personal al
            recopilar solo la información requerida para cumplir con los fines
            identificados. Recopilaremos solo la cantidad mínima de información
            requerida para facilitar los Servicios. Sin embargo, podemos
            proporcionarle la opción de compartir información adicional para
            mejorar su uso de los Servicios. Compartiremos su información
            personal con terceros solo de la manera que se describe en esta
            Política de privacidad.
          </p>

          <p>
            Es posible que, en relación con algunos, pero no todos, nuestros
            Servicios, tenga la capacidad de permitir que otros (Colaboradores
            Invitados) accedan a su cuenta. Tenga en cuenta que al proporcionar
            a los Colaboradores Invitados acceso a su cuenta, también les
            proporcionará acceso a cualquier Información Personal en su cuenta.
            Si bien el Colaborador Invitado debe aceptar nuestros "Documentos
            Legales", no asumimos ninguna responsabilidad por la recopilación,
            el uso o la divulgación de su Información Personal por parte de su
            Colaborador Invitado. Es su responsabilidad asegurarse de que su
            Colaborador Invitado cumpla con los estándares de privacidad que no
            sean menos estrictos que los nuestros.
          </p>

          <p>
            Cuando utiliza ciertos Servicios, tiene la opción de compartir
            información con otras cuentas en las que se ha registrado. Puede
            elegir, de vez en cuando, conectar a la Compañía con otros
            proveedores de servicios externos para utilizar estos servicios
            junto con las Aplicaciones de la Compañía. Los propósitos pueden
            incluir guardar copias de registros, importar datos de fuentes
            externas, comunicaciones con terceros y realizar funciones de pago y
            depósito directo, entre otros. En tales casos, las credenciales de
            su cuenta y contraseña y otra información requerida con respecto a
            dicho servicio de terceros pueden ser almacenadas por la Compañía
            con el fin de proporcionar este servicio.
          </p>
        </section>
        <section>
          <h2>Limitación del Uso, Divulgación y Retención</h2>
          <p>
            La Compañía no utilizará ni divulgará Información Personal para
            fines distintos de los identificados para los Servicios o cualquier
            otro propósito que identifiquemos de vez en cuando, como llevar a
            cabo el tratamiento (la provisión, coordinación o administración de
            atención médica y servicios relacionados por uno o más proveedores
            de atención médica, incluida la coordinación o administración de la
            atención médica con un tercero; consulta entre proveedores de
            atención médica relacionada con un paciente; o la remisión de un
            paciente para la atención médica de un proveedor a otro), el pago
            (las actividades de un proveedor de atención médica para obtener el
            reembolso por la prestación de atención médica, incluidas las
            determinaciones de elegibilidad o cobertura, la facturación y la
            revisión de los servicios con respecto a la necesidad médica o la
            idoneidad de la atención) y las operaciones de atención médica
            (realizar actividades de evaluación y mejora de la calidad, revisar
            la competencia de los profesionales de la salud, llevar a cabo
            programas de capacitación, realizar o organizar la revisión médica,
            los servicios legales y las funciones de auditoría, la gestión y
            planificación empresarial, entre otros).
          </p>

          <p>
            También nos aseguramos de que solo los empleados responsables de las
            operaciones de los Servicios tengan acceso físico o técnico a la
            Información Personal y solo cuando dicho acceso sea necesario para
            realizar el trabajo autorizado por sus supervisores.
          </p>

          <p>
            Retenemos toda la Información Personal proporcionada por usted en
            servidores seguros, al igual que nuestros socios externos y
            proveedores de servicios. Estos datos estarán sujetos a las leyes de
            las jurisdicciones pertinentes. Nuestros proveedores de servicios
            externos, incluidas las instituciones financieras, tienen sus
            propios "Documentos Legales" que regirán su relación con estos
            terceros. Usted es responsable de revisar y aceptar estos
            "Documentos Legales". Si no está de acuerdo con estos "Documentos
            Legales", no debe utilizar los servicios ofrecidos por dichos
            proveedores de servicios externos.
          </p>

          <p>
            En el futuro, podemos recopilar información, incluida la Información
            Personal, para permitirle acceder a los servicios proporcionados por
            nuestros socios externos y proveedores de servicios. Usted estará
            sujeto a los "Documentos Legales" de estos proveedores de servicios
            externos. Usted es responsable de revisar y aceptar estos
            "Documentos Legales". Si no está de acuerdo con los "Documentos
            Legales", no debe utilizar los servicios ofrecidos por dichos
            proveedores de servicios externos.
          </p>

          <p>
            Cuando la Compañía presta algunos servicios, parte de los datos se
            almacenan en caché en la memoria del dispositivo. Si la aplicación
            se coloca en segundo plano sin cerrarse, los datos pueden permanecer
            en la memoria caché. No asumimos ninguna responsabilidad por
            cualquier visualización no autorizada de estos datos por parte de
            terceros.
          </p>
        </section>
        <section>
          <h2>Privacidad Infantil</h2>
          <p>
            Si se entera de que un menor nos ha proporcionado información
            personal sin el consentimiento legal de un tutor o de los padres,
            puede alertarnos a{" "}
            <a href="mailto:compliance@UltimedPharmacy.com">
              complianceUltimedPharmacy.com
            </a>
            .
          </p>

          <p>
            Si nos enteramos de que hemos recopilado ilegalmente cualquier
            información personal de menores, tomaremos medidas inmediatas para
            eliminar dicha información y cancelar la cuenta de usuario del
            menor.
          </p>
        </section>
        <section>
          <h2>Exactitud</h2>
          <p>
            La Compañía confía en usted para garantizar que la Información
            Personal que nos proporcione mientras utiliza los Servicios sea tan
            precisa, completa y actualizada como sea necesario para los fines
            para los que se utilizará. Si lo solicita, la Compañía le
            proporcionará información sobre si tenemos alguna de sus Información
            Personal. Puede hacer cambios, solicitar la eliminación o
            correcciones de la Información Personal en cualquier momento
            poniéndose en contacto con nosotros a través de la información de
            contacto que se indica a continuación. Responderemos a su solicitud
            dentro de un plazo razonable.
          </p>

          <p>
            También hacemos todo lo posible para garantizar la exactitud de la
            información en nuestros informes, pantallas, artículos y consultas
            de soporte. Sin embargo, debe verificar toda la información creada a
            partir de su uso de los Servicios y le recomendamos que consulte a
            un profesional antes de completar cualquier presentación
            gubernamental o reglamentaria o confiar en la información, ya que el
            uso de esta información es bajo su propio riesgo.
          </p>

          <p>
            Usted es responsable de garantizar que la información que ha
            proporcionado sea veraz, precisa, confiable y completa.
          </p>

          <h2>Información de Tarjeta de Pago</h2>
          <p>
            Para garantizar la seguridad de sus datos de tarjetas de crédito y
            los de sus clientes, la Compañía no almacena números de tarjetas de
            crédito. Para fines de procesamiento de pagos, la Compañía utiliza
            un proveedor de servicios externo llamado Accepta Payments. Para
            obtener más información sobre Accepta Payments, incluidos sus
            términos de uso y políticas de privacidad, visite:{" "}
            <a href="https://acceptapayments.com">
              https://acceptapayments.com
            </a>
            .
          </p>
        </section>
        <section>
          <h2>Apertura</h2>
          <p>
            La Compañía pone a disposición del público un aviso claro y
            transparente de sus prácticas de privacidad. Nuestros avisos
            describen la recopilación, el uso, la divulgación y la protección de
            la Información Personal y proporcionan la información de contacto de
            nuestra persona de contacto de privacidad.
          </p>

          <p>
            La Compañía le informará en la primera oportunidad razonable al
            descubrir o ser advertido de un incidente en el que su Información
            Personal se pierda, robe, acceda, use, divulgue, copie, modifique o
            elimine por personas no autorizadas o de manera no autorizada. En el
            caso de que la PHI sea accedida, utilizada, divulgada, copiada,
            modificada, robada o eliminada por personas no autorizadas, le
            notificaremos de acuerdo con la Regla de Notificación de
            Incumplimiento de HIPAA, 45 CFR §§ 164.400-414.
          </p>
        </section>
        <section>
          <h2>Características Adicionales</h2>
          <p>
            El Sitio puede ofrecer blogs de acceso público y foros de la
            comunidad. Debe tener en cuenta que cualquier información que
            proporcione en estas áreas puede ser leída, recopilada y utilizada
            por otras personas que accedan a ellas. No asumimos ninguna
            responsabilidad por cualquier información que usted o sus empleados,
            agentes, contratistas y representantes publiquen en el Sitio, y
            usted acepta indemnizar y eximir de responsabilidad a la Compañía
            por cualquier pérdida, costo, queja, daño, reclamo o responsabilidad
            que surja de dicha publicación o publicación.
          </p>

          <p>
            Algunas de las funcionalidades de los Servicios y el Sitio
            interoperan con, y dependen en gran medida de, las interfaces de
            programación de aplicaciones (API) de terceros. Podemos recopilar
            Información Personal de estas API de terceros en la medida en que
            usted nos autorice a hacerlo, y nuestra recopilación, uso y
            divulgación de esa Información Personal se regirá por esta política
            de privacidad.
          </p>

          <p>
            Nuestro Sitio puede incluir enlaces a otros sitios web cuyas
            prácticas de privacidad pueden diferir de las de la Compañía. Si
            envía Información Personal a cualquiera de esos sitios, su
            información se rige por sus políticas de privacidad. Le recomendamos
            que lea detenidamente la política de privacidad de cualquier sitio
            web que visite.
          </p>
        </section>
        <section>
          <h2>Seguridad</h2>
          <p>
            La Compañía emplea medidas de seguridad técnicas y organizativas
            adecuadas para proteger la Información Personal contra el acceso,
            uso, divulgación, alteración o destrucción no autorizados. Sin
            embargo, ninguna medida de seguridad es infalible, y no podemos
            garantizar la seguridad absoluta de la Información Personal.
          </p>

          <p>
            La Compañía tiene la capacidad de auditar y controlar el acceso a la
            Información Personal a través de medidas de seguridad, incluidas las
            contraseñas de autenticación, el cifrado de datos y el acceso basado
            en roles. Además, utilizamos tecnologías y procesos avanzados para
            proteger la Información Personal, pero la seguridad de la
            información también depende de su propio comportamiento, como la
            protección de sus credenciales de inicio de sesión y el uso de
            contraseñas seguras.
          </p>

          <p>
            Aunque hacemos todo lo posible para proteger la Información
            Personal, debe tener en cuenta que ningún sistema de seguridad es
            completamente seguro. No asumimos ninguna responsabilidad por la
            seguridad de la Información Personal fuera de nuestro control.
          </p>
        </section>
        <section>
          <h2>Cambio de la Política de Privacidad</h2>
          <p>
            La Compañía se reserva el derecho de actualizar esta Política de
            privacidad en cualquier momento. Los cambios a la Política de
            privacidad se publicarán en el Sitio, y se le notificará mediante la
            publicación de una notificación visible en el Sitio o por correo
            electrónico. Su uso continuado de los Servicios después de cualquier
            modificación de esta Política de privacidad constituirá su
            aceptación de los cambios. Si no acepta los cambios en la Política
            de privacidad, debe dejar de usar los Servicios.
          </p>

          <p>
            En caso de una fusión, adquisición o venta de todos o parte de
            nuestros activos, la Compañía se compromete a tomar medidas
            razonables para garantizar que la Información Personal que
            recolectamos sea transferida a una entidad que se comprometa a
            respetar esta Política de privacidad.
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicyEs;
