import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FrameShadow from '../components/FrameShadow';
import Services from '../components/Services';
import ContactUs from '../components/ContactUs';
import Main from './Main';





const Home = () => {
  return (
        <div className="home">
          <Main></Main>
          <FrameShadow></FrameShadow>
          <Services></Services>
          <ContactUs></ContactUs>
    
        </div>
  );
};

export default Home;
