import React from "react";
import { useTranslation } from "react-i18next";
import image2 from "../assets/shadow.svg";
import image3 from "../assets/shadow-down.svg";
import imge4 from "../assets/spin-logo-b.svg";
import "../App.css";

const FrameShadow = () => {
  const { t } = useTranslation();

  return (
    <div className="fram-shadow" id="about">
      <img src={image2} alt="" className="shadow-image upper-curve" />
      <div className="shadow-box">
        <div className="content-box">
          <p className="titile">{t('frame_shadow_title')}</p>
          <div className="content">
            {t('frame_shadow_content')}
          </div>
          <img src={imge4} alt="" className="logo-TT" />
        </div>
      </div>
      <img src={image3} alt="" className="shadow-image" />
    </div>
  );
};

export default FrameShadow;
