import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import '../App.css';
import mapImage from '../assets/map.svg'; 
import LordiconLottie from './react-lottie';

const ContactUs = () => {
  const { t, i18n } = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');
  const [statusKey, setStatusKey] = useState(''); 

  useEffect(() => {
    if (statusKey) {
      setStatus(t(statusKey)); 
    }
  }, [i18n.language, statusKey, t]); 

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        'https://api.nylas.com/send',
        {
          to: [{ email: 'info@medidohealth.com' }],
          subject: `${t('contact.subject')} ${name}`,
          body: `${t('contact.name')}: ${name}<br>${t('contact.email')}: ${email}<br>${t('contact.phone')}: ${phone}<br>${t('contact.message')}: ${message}`,
        },
        {
          headers: {
            'Authorization': `Bearer eIMrVD6bSm4Ak9jaAbZBuwoYPnjh71`,
            'Content-Type': 'application/json',
          },
        }
      );

      setStatusKey('contact.email_sent_success');
    } catch (error) {
      console.error('Error sending email:', error);
      setStatusKey(`contact.email_sent_error: ${error.response ? error.response.data.message : error.message}`); 
    }
  };

  return (
    <div className="contact-us" id="contact">
      <div className="contact-form">
        <p className="contact-title">
          <LordiconLottie speed={0.02} />
          {t('contact.title')}
        </p>
        <p className="contact-description">
          {t('contact.description')}
        </p>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              id="name"
              name="name"
              placeholder={t('contact.name_placeholder')}
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              id="email"
              name="email"
              placeholder={t('contact.email_placeholder')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder={t('contact.phone_placeholder')}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <textarea
              id="message"
              name="message"
              placeholder={t('contact.message_placeholder')}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          <button type="submit">{t('contact.send_button')}</button>
          <p>{status}</p>
        </form>
      </div>
      <div className='rect'></div>
      <div className="contact-map"> 
        <img src={mapImage} alt={t('contact.title')} />
      </div>
    </div>
  );
};

export default ContactUs;
