// src/components/Latest.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const Latest = () => {
  const { t } = useTranslation();

  const communications = [
    { text: t('latest.comm1'), icon: "icon-communications" },
    { text: t('latest.comm2'), icon: "icon-vacation" },
    { text: t('latest.comm3'), icon: "icon-pass-tray" },
    { text: t('latest.comm4'), icon: "icon-admin-changes" },
    { text: t('latest.comm5'), icon: "icon-medlink-update" },
    { text: t('latest.comm6'), icon: "icon-abatnex-info" },
    { text: t('latest.comm7'), icon: "icon-service-guide" },
    { text: t('latest.comm8'), icon: "icon-medicine-shipment" },
    { text: t('latest.comm9'), icon: "icon-medicate-program" },
    { text: t('latest.comm10'), icon: "icon-service-update" },
    { text: t('latest.comm11'), icon: "icon-medcycle-service" },
    { text: t('latest.comm12'), icon: "icon-feedback" }
  ];

  return (
    <div>
      <p className='Latest-t'>{t('latest.title')}</p>
      <div className="communications-grid">
        {communications.map((comm, index) => (
          <div className="card" key={index}>
            <div className={comm.icon}></div>
            <p>{comm.text}</p>
            <button>
              <span>{t('latest.learn_more')}</span>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Latest;
